import { createSlice } from "@reduxjs/toolkit";

const initialState = {  
  serverHost: "https://jmdieiktm9.execute-api.eu-west-1.amazonaws.com/ars",
  nightMode: false,
  showAnwers: false,
  narratorMode: 'Exámen',
  narratorRepeat: 'No',
  narratorVel: 'Normal',
  voiceId: 'Laura',
  fontSize: 'Normal',
  numQuestions: '10',
  showWarning: true,
};

// Get config from local storage if exists
const storedConfig = JSON.parse(localStorage.getItem('config'));
const initialConfig = storedConfig ? { ...initialState, ...storedConfig } : initialState;

export const configSlice = createSlice({
  name: "config",
  initialState: initialConfig,
  reducers: {
    setConfig: (state, action) => {
      const newConfig = {
        ...state,
        ...action.payload
      };
      localStorage.setItem('config', JSON.stringify(newConfig));
      return newConfig;
    },
    restartConfig: () => {
      localStorage.removeItem('config');
      localStorage.removeItem('viededPDFS');
      return initialState;
    },
    setShowWarning: (state, action) => {
      state.showWarning = action.payload;
      localStorage.setItem('config', JSON.stringify(state));
    }
  }
});

export const { setConfig, restartConfig, setShowWarning } = configSlice.actions;
export const selectConfig = (state) => state.config;
export default configSlice.reducer;