import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from 'react-router-dom';
import { selectToken } from "../slices/tokenSlice";
import { selectScreen, setToggleOptions } from "../slices/screenSlice";
import { Container, Navbar, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCog, faList, faHome, faUser, faUserCog, faBook } from "@fortawesome/free-solid-svg-icons"


function RouteButton({ route, icon }) {
  const location = useLocation();
  return (
    <Link to={route}>
      <Button
        bsPrefix="btn-header"
        className={`${location.pathname === route ? 'success-color' : 'blue-color'} me-1`}>
        <FontAwesomeIcon className="clickable" icon={icon} />
      </Button>
    </Link>
  );
}

function OptionsButton({ onClick, icon }) {
  const { showOptions } = useSelector(selectScreen);
  return (
    <Button
      bsPrefix="btn-header"
      className={showOptions ? 'success-color' : 'blue-color'} 
      onClick={onClick}>
        <FontAwesomeIcon className="clickable" icon={icon} />
    </Button>
  );
}

function Header() {
  const dispatch = useDispatch();
  const { userInfo, tokenValid } = useSelector(selectToken);
  const role = userInfo.role;
  const buttonsConfig = [
    { route: '/home', icon: faHome },
    { route: '/quiz', icon: faList },
    { route: '/reader', icon: faBook },
    ...(role === 'admin' ? [{ route: '/users', icon: faUserCog }] : []),
    // { route: '/help', icon: faInfoCircle },
    { route: '/options', icon: faCog }
  ];
  
  const handleShowOptions = useCallback(() => dispatch(setToggleOptions()), [dispatch]);

  return (
    <Navbar className="header" bg="dark" expand="lg">
      <Container className="text-center justify-content-center">
        <div>
          <h1 className="header-title">Permanencia</h1>
          <h1 className="header-title">FAS 2024</h1>
          {tokenValid && buttonsConfig.map(({ route, icon }) => (
            <React.Fragment key={route}>
              {route === '/options' ?
                <OptionsButton onClick={handleShowOptions} icon={icon} />
                :
                <RouteButton route={route} icon={icon} />
              }
            </React.Fragment>
          ))}
        </div>
      </Container>
    </Navbar>
  );
};


export default Header;