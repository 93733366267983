import { createSlice } from "@reduxjs/toolkit";

const initialState = {  
  pdfs: [],
  urls: {},
  selectedPDF: {nombre_bloque: "", nombre_tema: "", url: ""},
  lines: [],
  viededPDFS: []
};

const viededPDFS = JSON.parse(localStorage.getItem('viededPDFS'));
const state = viededPDFS ? { ...initialState, viededPDFS: viededPDFS } : initialState;

export const readerSlice = createSlice({
  name: "reader",
  initialState: state,
  reducers: {
    setPDFs: (state, action) => {
      const {pdfs, urls} = action.payload;
      state.pdfs = pdfs;
      state.urls = urls;
      state.selectedPDF = {...pdfs[0]};
      state.lines = pdfs[0].lines;
    },
    setSeletedPDF: (state, action) => {
      const pdf = action.payload;
      state.selectedPDF = {...pdf};
      state.lines = pdf.lines;
      state.viededPDFS.push(pdf.nombre_tema);
      localStorage.setItem('viededPDFS', JSON.stringify(state.viededPDFS));
    }
  }
});


export const { setPDFs, setSeletedPDF } = readerSlice.actions;
export const selectPDF = (state) => state.reader;
export default readerSlice.reducer;