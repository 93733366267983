import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import React, { useEffect } from "react";
import { get } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { selectConfig } from "./slices/configSlice";
import { selectToken, setToken } from "./slices/tokenSlice";
import { selectScreen } from "./slices/screenSlice";
import { setQuestions } from "./slices/questionsSlice";
import { setPDFs } from "./slices/readerSlice";
import { setOrganization } from "./slices/organizationSlice";
import { setStatus } from "./slices/statusSlice";
import { Container, Row, Col } from "react-bootstrap";
import Login from "./components/Login";
import Users from "./components/Users";
import Header from "./components/Header";
import Home from "./components/Home";
import Quiz from "./components/Quiz";
import Reader from "./components/Reader";
import Help from "./components/Help";
import Options from "./components/Options";
import Footer from "./components/Footer";
import Warning from "./components/Warning";


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { showOptions } = useSelector(selectScreen)
  const { serverHost, nightMode, showWarning } = useSelector(selectConfig);
  const { token, tokenValid } = useSelector(selectToken);

  useEffect(() => {
    window.speechSynthesis.cancel();

    const getOrganization = async () => {
      try {
        const url = `${serverHost}/get_organization?app=permanencia_2024`;
        const response = await fetch(url);
        const data = await response.json();
        dispatch(setOrganization(data.body));
      } catch (error) {
        console.error(error);
      }
    };

    const getQuestions = async () => {
      try {
        const url = `${serverHost}/get_questions?app=permanencia_2024`;
        const response = await fetch(url);
        const data = await response.json();
        const urls = data.urls;
        const requests = urls.map(url => fetch(url));
        const responses = await Promise.all(requests);
        const questionsData = await Promise.all(responses.map(res => res.json()));
        const mergedQuestions = questionsData.flat();
        dispatch(setQuestions(mergedQuestions));
      } catch (error) {
        console.error(error);
      }
    };

    const getPDFs = async () => {
      try {
        const url = `${serverHost}/get_pdfs?app=permanencia_2024`;
        const response = await fetch(url);
        const data = await response.json();
        const { urls_html, urls_pdf } = data;
        const requests = urls_html.map(url => fetch(url));
        const responses = await Promise.all(requests);
        const pdfsData = await Promise.all(responses.map(res => res.json()));
        const payload = {"pdfs": pdfsData, "urls": urls_pdf}
        dispatch(setPDFs(payload));
      } catch (error) {
        console.error(error);
      }
    };

    if (tokenValid) {
      navigate('/home');
      getOrganization();
      getQuestions();
      getPDFs();
    } else if (token && !tokenValid) {
      const url = `${serverHost}/users/check_token?token=${token}`;
      get(url).then((response) => {
        if (response.data.statusCode === 200) {
          const user_status = response.data.user_status;
          const new_token = response.data.token;
          dispatch(setToken(new_token));
          dispatch(setStatus(user_status));
        } else {
          navigate('/login');
        }
      }).catch(function (error) {
        console.log(error);
      });
    } else {
      navigate('/login');
    }

  }, [token, tokenValid, dispatch, serverHost]);

  return (
    <div className={nightMode ? "night-mode" : ""}>
      <Container className="d-flex flex-column min-vh-100">
        <Row className="no-padding flex-grow-1 mb-4">
          <Col className="padding-xxs" lg={{ span: 8, offset: 2 }}>
            <Header />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/users" element={<Users />} />
              <Route path="/home" element={<Home />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/reader" element={<Reader />} />
              <Route path="/help" element={<Help />} />
            </Routes>
            {showOptions ? <Options /> : <></>}
            {showWarning && tokenValid ? <Warning /> : <></>}
          </Col>
        </Row>
        {location.pathname !== "/quiz" && <Footer />}
      </Container>
    </div>
  );
}


export default App;