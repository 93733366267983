import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useSelector, useDispatch } from "react-redux";
import { post } from "axios";
import { selectQuestions } from "../slices/questionsSlice";
import { selectConfig } from "../slices/configSlice";
import { selectToken } from "../slices/tokenSlice";
import { selectScreen } from "../slices/screenSlice";
import { selectStatus, setMarkQuestion, setErrorsQuestion, setShowResult, setResult } from "../slices/statusSlice";
import { Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCopy,
  faQuestionCircle,
  faInfoCircle,
  faGrinWink,
  faPlayCircle,
  faPause,
  faStop,
  faStepBackward,
  faStepForward,
  faPlay,
  faLongArrowAltUp
} from "@fortawesome/free-solid-svg-icons"


function Quiz() {
  const dispatch = useDispatch();
  const synth = window.speechSynthesis;
  const { showAnwers, serverHost, narratorMode, narratorVel, narratorRepeat } = useSelector(selectConfig);
  const { token } = useSelector(selectToken);
  const { quizQuestions, quizPath, testId } = useSelector(selectQuestions);
  const { markeds, result } = useSelector(selectStatus);
  const { showOptions } = useSelector(selectScreen);
  const [quiz, setQuiz] = useState(quizQuestions);
  const [showModal, setShowModal] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);
  const [upIsVisible, setUpIsVisible] = useState(false);
  const [correctisDisabled, setCorrectisDisabled] = useState(true);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [aux, setAux] = useState(0);
  const prevPlayingRef = useRef();
  const prevPausedRef = useRef();
  const prevIndexQuestionRef = useRef();

  useEffect(() => {
    if (playing !== prevPlayingRef.current || indexQuestion !== prevIndexQuestionRef.current) {
      synth.cancel();
      if (playing) {
        if ((indexQuestion >= 0) && (indexQuestion < quiz.length) && !paused) {
          play();
        } else if (indexQuestion >= quiz.length) {
          setIndexQuestion(0);
          // setPlaying(false);
          goStart();
        } else if (indexQuestion <= 0) {
          setIndexQuestion(0);
        }
      }
    } else if (paused !== prevPausedRef.current) {
      playing && paused ? synth.pause() : synth.resume();
    } else if (showOptions) {
      handleStop();
    }

    const adjustRightPosition = () => {
      var parentElement = $('.quiz');
      var fixedElement = $('.fixed-bottom-right');
      if (parentElement.length && fixedElement.length) {
        var parentRightEdge = parentElement.offset().left + parentElement.outerWidth();
        var windowRightEdge = $(window).width();
        fixedElement.css('right', windowRightEdge - parentRightEdge + 'px');
      }
    };
    const onScroll = () => {
      setUpIsVisible($(window).scrollTop() > 100);
    };

    adjustRightPosition();
    $(window).on('scroll', onScroll);
    $(window).on('resize', adjustRightPosition);
    prevPlayingRef.current = playing;
    prevIndexQuestionRef.current = indexQuestion;
    prevPausedRef.current = paused;

    return () => {
      $(window).off('scroll', onScroll);
      $(window).off('resize', adjustRightPosition);
    };
  }, [playing, paused, indexQuestion, quiz, showModal, upIsVisible, aux, result.showResult, showOptions]);

  const getEnunciadoClasses = (question, index) => {
    let classes = ["quiz-question"];
    if (playing) classes.push("clickable");
    if (markeds.includes(question.uuid)) classes.push("primary-color");
    if (playing && indexQuestion === index) classes.push("yellow-background");

    return classes.join(" ");
  };

  const getOptionClasses = (question, option) => {
    let classes = ["clickable", "quiz-answer"];
    if (question.respuestas && question.respuestas.includes(option)) {
      classes.push("active");
      if (showAnwers || result.showResult) {
        classes.push(question.correctas.includes(option) ? "correct" : "incorrect");
      }
    }

    return classes.join(" ");
  };

  const goToQuestion = (index) => {
    $(".quiz .quiz-question").removeClass("yellow-background");
    const question = $(`#question${index}`)[0]
    let position = question.getBoundingClientRect();
    $(`#question${index}`).addClass("yellow-background");
    window.scrollTo(position.left, position.top + window.scrollY - 10);
  };

  const goStart = () => {
    $(".quiz .quiz-question").removeClass("yellow-background");
    window.scrollTo(0, 0);
  };

  const getQuestionText = (question) => {
    let opciones = `;;A;${question.opciones.A};;B;${question.opciones.B}`;
    if (question.opciones.C) {
      opciones = opciones + `;;C;${question.opciones.C}`;
    }
    if (question.opciones.D) {
      opciones = opciones + `;;D;${question.opciones.D}`;
    }
    let correcta_single = `;${question.opciones[question.correctas[0]]}`;
    let correcta_full = `;;La respuesta correcta es;${question.opciones[question.correctas[0]]}`;
    let mode_text = narratorMode === "Estudio" ? ";" : opciones;
    let correcta = narratorMode === "Estudio" ? correcta_single : correcta_full;
    let text = `${question.enunciado}${mode_text}${correcta}`;
    if (narratorRepeat !== "No") {
      let times = parseInt(narratorRepeat);
      text = text + ";;";
      text = text.repeat(times);
    }

    return text;
  };

  const play = () => {
    goToQuestion(indexQuestion);
    const message = getQuestionText(quiz[indexQuestion]);
    const messageParts = message.split(';');
    let currentIndex = 0;
    const speak = (textToSpeak) => {
      const msg = new SpeechSynthesisUtterance();
      msg.text = textToSpeak;
      const rate = narratorVel === "Normal" ? 1 : parseFloat(narratorVel.replace("x", ""));
      msg.rate = rate + 0.2;
      msg.onend = function () {
        currentIndex++;
        if (currentIndex < messageParts.length) {
          setTimeout(() => {
            speak(messageParts[currentIndex])
          }, 50);
        } else {
          setIndexQuestion(indexQuestion + 1);
        }
      };
      speechSynthesis.speak(msg);
    }
    speak(messageParts[0]);
  };

  const handleStop = () => {
    setIndexQuestion(0);
    setPlaying(false);
    setPaused(false);
    goStart();
  };

  const getScore = (total, corrects, wrongs) => {
    const correct_sum = 100 / total;
    const score = (correct_sum * corrects - (correct_sum / 3) * wrongs).toFixed(2);

    return score;
  };

  const setResponse = (questionId, optionSelected) => {
    if (result.showResult) return;
    if (correctisDisabled) setCorrectisDisabled(false);
    setQuiz(prevData => prevData.map(question => {
      if (question.uuid === questionId) {
        let respuestas = Object.assign([], question.respuestas);
        // let remaining = question.correctas.length - respuestas.length;
        if (respuestas) {
          if (respuestas.includes(optionSelected)) {
            respuestas.splice(respuestas.indexOf(optionSelected), 1);
          } else {
            if (respuestas.length >= question.correctas.length) {
              respuestas.splice(0, 1);
            }
            respuestas.push(optionSelected);
          }
        } else {
          respuestas = [optionSelected];
        }
        return {
          ...question,
          "respuestas": respuestas
        };
      }
      return question;
    }));
  };

  function Title() {
    return (
      <div className="quiz mb-3">
        {quizPath.map((item, index) => (
          <div className="inline" key={item}>
            {item.split(":")[0]}
            {(index + 1 < quizPath.length) ? <FontAwesomeIcon className="font-xxxs me-2 ms-2" icon={faChevronRight} /> : <></>}
          </div>
        ))}
        <span className="ms-1">({quizQuestions.length})</span>
      </div>
    )
  };

  function MessageModal() {
    const handleClose = () => {
      setShowModal(false);
    };

    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        onClick={handleClose}
      >
        <Modal.Body className="text-center">
          Pregunta copiada
          <FontAwesomeIcon className="font-sm me-2 ms-2" icon={faGrinWink} />
        </Modal.Body>
      </Modal >
    )
  };

  function Info(props) {
    const { question } = props;
    return (
      <div dangerouslySetInnerHTML={{ __html: question.explicacion }} className="quiz-explication"></div>
    )
  };

  function Options(props) {
    const { question, index } = props;
    const questionId = question.uuid;

    const focusQuestion = () => {
      const question_div = $(`#question${index}`)[0]
      let position = question_div.getBoundingClientRect();
      window.scrollTo(position.left, position.top + window.scrollY - 10);
    };

    const handleCopy = () => {
      let question_txt = question.enunciado;
      for (const option in question.opciones) {
        question_txt += `\n${option}. ${question.opciones[option]}`;
      }
      const elem = document.createElement("textarea");
      elem.value = question_txt;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);
      setShowModal(true);
    };

    const handleShowInfo = () => {
      if (!playing) focusQuestion();
      setQuiz(prevData => prevData.map(q => {
        if (q.uuid === questionId) {
          return {
            ...q,
            showInfo: q.showInfo ? !q.showInfo : true
          };
        }
        return q;
      }));
    };

    const handleMarkQuestion = () => {
      if (!playing) focusQuestion();
      setAux(aux + 1);
      dispatch(setMarkQuestion(question));
      const url = `${serverHost}/users/mark_question`;
      const userData = {
        "token": token,
        "test_id": question['test_id'],
        "question_id": question['uuid']
      }
      post(url, userData).then((response) => {
        if (response.data.statusCode === 200) {
        } else {
        }
      }).catch(function (error) {
        console.log(error);
      });
    };

    return (
      <div className="ms-1">
        <div className="inline-block clickable me-3" onClick={handleCopy}>
          <FontAwesomeIcon className={`${!showModal ? "primary-color" : ""} font-sm me-2`} icon={faCopy} />
          <span className="block">Copiar</span>
        </div>
        <div className="inline-block clickable me-3" onClick={handleMarkQuestion}>
          <FontAwesomeIcon className={`${!markeds.includes(question.uuid) ? "primary-color" : ""} font-sm me-2`} icon={faQuestionCircle} />
          <span className="block">Dudas</span>
        </div>
        <div className="inline-block clickable" onClick={handleShowInfo}>
          <FontAwesomeIcon className={`${!question.showInfo ? "primary-color" : ""} font-sm me-2`} icon={faInfoCircle} />
          <span className="block">Info</span>
        </div>
      </div>
    )
  };

  function PlayButton() {
    return (
      <Button
        className="btn-msize white-color blue-background top-right"
        onClick={() => setPlaying(true)}>
        <FontAwesomeIcon icon={faPlayCircle} />
      </Button>
    );
  };

  function ControlButtons() {
    return (
      <div className="fixed-bottom-right">
        <Button
          className="btn-msize white-color blue-background float-right"
          onClick={() => setIndexQuestion(indexQuestion + 1)}>
          <FontAwesomeIcon icon={faStepForward} />
        </Button>
        {paused ?
          <Button
            className="btn-msize white-color blue-background float-right me-1"
            onClick={() => setPaused(false)}>
            <FontAwesomeIcon icon={faPlay} />
          </Button>
          :
          <Button
            className="btn-msize white-color blue-background float-right me-1"
            onClick={() => setPaused(true)}>
            <FontAwesomeIcon icon={faPause} />
          </Button>
        }
        <Button
          className="btn-msize white-color blue-background float-right me-1"
          onClick={() => setIndexQuestion(indexQuestion - 1)}>
          <FontAwesomeIcon icon={faStepBackward} />
        </Button>
        <Button
          className="btn-msize white-color blue-background float-right me-1"
          onClick={handleStop}>
          <FontAwesomeIcon icon={faStop} />
        </Button>
      </div>
    );
  };

  function CorrectButton() {
    const handleCorrectTest = (result) => {
      const url = `${serverHost}/users/correct_test`;
      const userData = {
        "token": token,
        ...result
      }
      post(url, userData).then(() => {
      }).catch(function (error) {
        console.log(error);
      });
    };

    const correctTest = () => {
      let corrects = [];
      let wrongs = [];
      quiz.forEach(function (question) {
        if (!question.respuestas) return;
        const { uuid, test_id } = question;
        const new_q = { uuid, test_id }
        if (question.correctas.every(v => question.respuestas.includes(v))) {
          corrects.push(new_q)
        } else if (question.respuestas.length > 0) {
          wrongs.push(new_q)
        }
      });
      const result = {
        "result": {
          "corrects": corrects,
          "wrongs": wrongs,
          "score": getScore(quiz.length, corrects.length, wrongs.length),
          "date": Math.floor(Date.now() / 1000)
        },
        "test_id": testId
      }
      dispatch(setErrorsQuestion(result));
      dispatch(setResult(result));
      dispatch(setShowResult(true));
      handleCorrectTest(result);
      window.scrollTo(0, 0);
    };

    return (
      <div className="fixed-bottom-right">
        <Button className="btn-msize float-right" disabled={correctisDisabled || result.showResult} variant="success" onClick={() => correctTest()}>
          Corregir
        </Button>
      </div>
    );
  };

  function UpButton() {
    return (
      <div className="fixed-bottom-right btn-up">
        <Button
          className="btn-msize primary-background float-right"
          onClick={() => window.scrollTo(0, 0)}>
          <FontAwesomeIcon icon={faLongArrowAltUp} />
        </Button>
      </div>
    );
  };

  function Result() {
    return (
      <div className="mt-2 mb-2 font-xs">
        Resultado: <b>{result.corrects.length}</b> aciertos, <b>{result.wrongs.length}</b> fallos, <b>{quiz.length - result.corrects.length - result.wrongs.length}</b> sin responder. <br />
        Nota: <b>{result.score}/100</b>
      </div>
    );
  };

  return (
    <Card className="card">
      <Card.Body className="padding-s">
        <Title />
        {result.showResult && <Result />}
        <div className="quiz">
          {!playing ? (<><PlayButton /><CorrectButton /></>) : (<ControlButtons />)}
          {upIsVisible && <UpButton />}
          {quiz.map((question, index) => (
            <div key={`${question.uuid}-${index}`} className="mb-4">
              <span id={`question${index}`} className={getEnunciadoClasses(question, index)} onClick={() => playing ? setIndexQuestion(index) : null}>
                {index + 1}. {question.enunciado}
              </span>
              <ul className="mb-3">
                {Object.keys(question.opciones).map((option) => (
                  question.opciones[option] && (
                    <li key={option} onClick={() => setResponse(question.uuid, option)} className={getOptionClasses(question, option)}>
                      {option}. {question.opciones[option]}
                    </li>
                  )
                ))}
              </ul>
              <Options question={question} index={index} />
              {question.showInfo ? <Info question={question} /> : <></>}
            </div>
          ))}
        </div>
        <MessageModal />
      </Card.Body >
    </Card >
  );
};


export default Quiz;