import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToggleOptions } from "../slices/screenSlice.js";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { selectConfig, setConfig, restartConfig } from "../slices/configSlice";


function Options() {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const [show, setShow] = useState(true);
  const [nightMode, setNightMode] = useState(config.nightMode);
  const [showAnwers, setShowAnwers] = useState(config.showAnwers);
  const [narratorMode, setNarratorMode] = useState(config.narratorMode);
  const [narratorRepeat, setNarratorRepeat] = useState(config.narratorRepeat);
  const [narratorVel, setNarratorVel] = useState(config.narratorVel);
  const [voiceId, setVoiceId] = useState(config.voiceId);
  const [fontSize, setFontSize] = useState(config.fontSize);
  const [numQuestions, setNumQuestions] = useState(config.numQuestions);

  const handleClose = () => {
    setShow(false);
    dispatch(setToggleOptions());
  };

  const saveConfig = () => {
    const updatedConfig = {
      nightMode,
      showAnwers,
      narratorMode,
      narratorRepeat,
      narratorVel,
      voiceId,
      numQuestions,
      fontSize,
    };
    dispatch(setConfig(updatedConfig));
    handleClose();
  };

  const resetConfig = () => {
    dispatch(restartConfig());
    handleClose();
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="font-sm">Opciones</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container className="font-xs" >
          <Row>
            <Col className="no-padding padding-right border-right">
              <Form.Label className="font-bold">Pantalla</Form.Label>
              <Form.Group className="mb-1" controlId="nightModeCheckbox">
                <Form.Check type="checkbox" checked={nightMode} onChange={(e) => setNightMode(e.target.checked)} label="Modo noche" />
              </Form.Group>
              <hr className="hr-2" />
              <Form.Label className="font-bold mt-2">Narrador</Form.Label>
              <Form.Group className="mb-1">
                <Form.Label>Modo</Form.Label>
                <Form.Select className="font-xs" defaultValue={narratorMode} onChange={(e) => setNarratorMode(e.target.value)}>
                  <option value="Estudio">Estudio</option>
                  <option value="Exámen">Exámen</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Velocidad</Form.Label>
                <Form.Select className="font-xs" defaultValue={narratorVel} onChange={(e) => setNarratorVel(e.target.value)}>
                  <option value="0.25x">0.25x</option>
                  <option value="0.5x">0.5x</option>
                  <option value="0.75x">0.75x</option>
                  <option value="Normal">Normal</option>
                  <option value="1.25x">1.25x</option>
                  <option value="1.5x">1.5x</option>
                  <option value="1.75x">1.75x</option>
                  <option value="2x">2x</option>
                </Form.Select>
              </Form.Group>
              <hr className="hr-2" />
              <Form.Label className="font-bold mt-2">Reiniciar app</Form.Label>
              <Form.Group className="mb-1">
                <Form.Label>Borrar progreso</Form.Label><br />
                <Button variant="danger" onClick={resetConfig}>Borrar</Button>
              </Form.Group>
            </Col>
            <Col className="no-padding padding-left" xs={{ span: 6 }}>
              <Form.Label className="font-bold">Mostrar respuestas</Form.Label>
              <Form.Group className="mb-1" controlId="QuizModeCheckbox">
                <Form.Check type="checkbox" label="Mostrar" checked={showAnwers} onChange={(e) => setShowAnwers(e.target.checked)}/>
              </Form.Group>
              <hr className="hr-2" />
              <Form.Label className="mt-2">&nbsp;</Form.Label>
              <Form.Group className="mb-1">
                <Form.Label>Repetir preguntas</Form.Label>
                <Form.Select className="font-xs" defaultValue={narratorRepeat} onChange={(e) => setNarratorRepeat(e.target.value)}>
                  <option value="No">No</option>
                  <option value="2">Dos veces</option>
                  <option value="3">Tres veces</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Tamaño texto</Form.Label>
                <Form.Select className="font-xs" defaultValue={fontSize} onChange={(e) => setFontSize(e.target.value)}>
                  <option value="Pequeño">Pequeño</option>
                  <option value="Normal">Normal</option>
                  <option value="Grande">Grande</option>
                </Form.Select>
              </Form.Group>
              <hr className="hr-2" />
              <Form.Label className="font-bold mt-2">Tests de repaso</Form.Label>
              <Form.Group className="mb-1">
                <Form.Label>Nº de preguntas</Form.Label>
                <Form.Select className="font-xs" defaultValue={numQuestions} onChange={(e) => setNumQuestions(e.target.value)}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="width-100">
          <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
          <Button className="float-end" onClick={saveConfig}>Guardar</Button>
        </div>
      </Modal.Footer>
    </Modal >
  );
};


export default Options;