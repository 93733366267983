import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Modal, Button } from "react-bootstrap";
import { setShowWarning } from "../slices/configSlice.js";


function Warning() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    dispatch(setShowWarning(false));
  };


  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="font-sm">Aviso importante</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container className="font-xs" >
          ¡Hola! Hemos realizado algunas actualizaciones en nuestro contenido para ofrecerte una mejor experiencia. Como resultado, tu progreso anterior en los cuestionarios se ha reiniciado. Te invitamos a descubrir las novedades. ¡Esperamos que disfrutes las mejoras!
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="width-100">
          <Button className="float-end" onClick={handleClose}>OK</Button>
        </div>
      </Modal.Footer>
    </Modal >
  );
};


export default Warning;