
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Row, Col, Accordion, InputGroup, Tab, Tabs, Modal, Spinner } from 'react-bootstrap';
import { selectConfig } from "../slices/configSlice";
import { setStatus } from "../slices/statusSlice";
import { setToken } from "../slices/tokenSlice";
import { post } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


function Login() {
  const dispatch = useDispatch();
  const { serverHost } = useSelector(selectConfig);

  const isPhoneNumberValid = (phone) => {
    const pattern = /^[0-9]{9}$/;
    return pattern.test(phone.trim());
  };

  function ContactInfo(){
    const adminPhone = "690156890";
    const adminMail = "ars_academy@outlook.com"
    const getMessageUrl = () => {
      return `https://api.whatsapp.com/send?phone=34${adminPhone}&text=Hola.%20Estoy%20interesado%20en%20la%20plataforma%20de%20estudios`;
    };
    const getMessageMail = () => {
      return `mailto:${adminMail}`
    };

    return (
      <>
        <div className="mt-1">
          <FontAwesomeIcon className="font-xxxs me-2 ms-2" icon={faChevronRight} />Whatsapp:
          <a className="link ms-1" target="_blank" href={getMessageUrl()}>{adminPhone}<FontAwesomeIcon className="ms-1" icon={faWhatsapp} /></a><br />
        </div>
        <div className="mt-1">
          <FontAwesomeIcon className="font-xxxs me-2 ms-2" icon={faChevronRight} />Correo:
          <a className="link ms-1" target="_blank" href={getMessageMail()}>{adminMail}<FontAwesomeIcon className="ms-1" icon={faPaperPlane} /></a><br />
        </div>
      </>
    );
  };

  function LoginForm() {
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneValidated, setPhoneValidated] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneExists, setPhoneExists] = useState(false);
    const [pin, setPin] = useState("");
    const [pinValidated, setPinValidated] = useState(false);
    const [pinValid, setPinValid] = useState(false);
    const [phoneValidMsg, setPhoneValidMsg] = useState("");

    const handlePhoneChange = (e) => {
      setPhoneNumber(e.target.value);
      setPhoneValidated(false);
      setPhoneValid(false);
    };

    const handlePinChange = (e) => {
      setPin(e.target.value);
      setPinValidated(false);
    };

    const handleGetPin = () => {
      const isPhoneValid = isPhoneNumberValid(phoneNumber);
      setPhoneValid(isPhoneValid);
      setPhoneValidated(true);
      if (isPhoneValid) {
        const url = `${serverHost}/users/get_pin`;
        const userData = { "phone": phoneNumber.trim() };
        setPhoneValidMsg("");
        setPhoneValidated(false);
        setLoading(true);
        post(url, userData).then((response) => {
          setLoading(false);
          const statusCode = response.data.statusCode 
          if (statusCode === 200) {
            setPhoneExists(true);
            setPhoneValidated(true);
          } else if (statusCode === 401) {
            setPhoneValidated(true);
            setPhoneValidMsg("Tu cuenta aún no ha sido confirmada");
          } else if (statusCode === 403) {
            setPhoneValidated(true);
            setPhoneValidMsg("Hemos detectado actividad inusual con tu cuenta. Por favor, ponte en contacto con con nosotros");
          } else {
            setPhoneValidated(true);
            setPhoneValidMsg("No hemos podido encontrar tu cuenta");
          }
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        setPhoneValidMsg("Introduce un número de teléfono válido");
      }
    };

    const handleLogIn = () => {
      const url = `${serverHost}/users/authenticate`;
      const userData = {
        "phone": phoneNumber.trim(),
        "pin": pin
      };
      setLoading(true);
      post(url, userData).then((response) => {
        setLoading(false);
        if (response.data.statusCode === 200) {
          dispatch(setToken(response.data.token));
          const user_status = response.data.user_status;
          const token = response.data.token;
          dispatch(setToken(token));
          dispatch(setStatus(user_status));
          setPinValidated(true);
          setPinValid(true);
        } else {
          setPinValidated(true);
          setPinValid(false);
        }
      }).catch(function (error) {
        console.log(error);
      });
    };

    return (
      <Form onSubmit={(e) => {e.preventDefault(); handleGetPin();}}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Nº de teléfono</Form.Label>
            <InputGroup>
              <InputGroup.Text className="prefix-size ps-1">+34</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Nº de teléfono"
                disabled={phoneValid && phoneExists}
                defaultValue={phoneNumber}
                onChange={handlePhoneChange}
                className={phoneValidated ? (phoneValid ? (phoneExists ? "form-valid" : "form-invalid") : "form-invalid") : "form-control"}>
              </Form.Control>
              {(phoneValidated && !phoneExists) && <div className="feedback-invalid">{phoneValidMsg}</div>}
              {phoneExists && <div className="feedback-valid mb-2">Se ha enviado una clave de acceso a tu teléfono</div>}
            </InputGroup>
          </Form.Group>
          {phoneExists ?
            <>
              <Form.Group className="mb-3" as={Col} md="6">
                <Form.Label>Clave de acceso</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Clave de acceso"
                  defaultValue={pin}
                  className={pinValidated ? (pinValid ? "form-valid" : "form-invalid") : "form-control"}
                  onChange={handlePinChange} />
                {(pinValidated && !pinValid) && <div className="feedback-invalid">Clave de acceso no válida</div>}
              </Form.Group>
              <div className="d-flex justify-content-center align-items-center">
                {loading ?
                  <Button variant="success" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />Iniciar sesión
                  </Button>
                  :
                  <Button variant="success" onClick={handleLogIn}>Iniciar sesión</Button>
                }
              </div>
            </>
            :
            <Col md="6" className="mt-4 mt-md-0">
              <Form.Label className="d-none d-sm-none d-md-block transparent">_</Form.Label>
              <div className="d-flex d-md-block justify-content-center align-items-center">
                {loading ?
                  <Button variant="success" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />Solicitar clave
                  </Button>
                  :
                  <Button variant="success" onClick={handleGetPin}>Solicitar clave</Button>
                }
              </div>
            </Col>
          }
        </Row>
      </Form>
    );
  };

  function SignUpForm() {
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showSingUpModal, setShowSingUpModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [phoneValidMsg, setPhoneValidMsg] = useState("Introduce un número de teléfono válido");

    const handleSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      const isPhoneValid = isPhoneNumberValid(phoneNumber);
      setPhoneValid(isPhoneValid);
      if (form.checkValidity() === true && isPhoneValid) {
        createUser();
      }
      setValidated(true);
    };

    const handlePhoneChange = (e) => {
      setValidated(false);
      setPhoneNumber(e.target.value);
    };

    const createUser = () => {
      const url = `${serverHost}/users/create`;
      const userData = {
        "phone": phoneNumber.trim(),
        "username": username,
        "name": name,
        "surname": surname
      };
      setLoading(true);
      post(url, userData).then((response) => {
        setLoading(false);
        if (response.data.statusCode === 201) {
          setShowSingUpModal(true);
        } else {
          setPhoneValid(false);
          setValidated(true);
          setPhoneValidMsg("El número de teléfono ya está registrado");

        }
      }).catch(function (error) {
        console.log(error);
      });
    };

    function TermsModal() {
      return (
        <Modal centered show={showTermsModal} onHide={() => setShowTermsModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="font-s">Términos y Condiciones del Registro</Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-xs text-justify ps-4 pe-4">
            <p className="font-bold">1. Aceptación de los Términos</p>
            <p>Al registrarse y utilizar nuestra plataforma de estudios (en adelante, "la Plataforma"), usted acepta estar vinculado por estos términos y condiciones (en adelante, "Términos"). Si no está de acuerdo con los Términos, no debe utilizar la Plataforma.</p>
  
            <p className="font-bold">2. Registro</p>
            <p>Para acceder a las funcionalidades de la Plataforma, debe registrarse utilizando su número de teléfono móvil. Este número será utilizado exclusivamente para fines de autenticación y para garantizar la seguridad de su cuenta.</p>
  
            <p className="font-bold">3. Autenticación de Doble Factor</p>
            <p>La Plataforma utiliza un sistema de autenticación de doble factor (2FA) para mejorar la seguridad de su cuenta. Recibirá un código temporal de 4 dígitos vía SMS que deberá ingresar para completar el proceso de inicio de sesión.</p>
  
            <p className="font-bold">4. Privacidad</p>
            <p>Nos comprometemos a proteger su privacidad y solo utilizaremos su número de teléfono para los fines mencionados. No compartiremos su número con terceros sin su consentimiento explícito, excepto cuando sea requerido por ley.</p>
  
            <p className="font-bold">5. Seguridad</p>
            <p>Nos esforzamos por mantener la seguridad de su información. Sin embargo, no podemos garantizar la seguridad absoluta. En caso de una brecha de seguridad, nos comprometemos a informar a los usuarios afectados lo antes posible.</p>
  
            <p className="font-bold">6. Uso Aceptable</p>
            <p>Usted se compromete a no utilizar la Plataforma para fines ilícitos o no autorizados. Además, no debe intentar vulnerar las medidas de seguridad ni realizar actividades que puedan dañar la Plataforma o su funcionamiento.</p>
  
            <p className="font-bold">7. Modificaciones en los Términos</p>
            <p>Nos reservamos el derecho a modificar estos Términos en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en la Plataforma. Su uso continuado de la Plataforma constituye la aceptación de los Términos modificados.</p>
  
            <p className="font-bold">8. Ley Aplicable y Jurisdicción</p>
            <p>Estos Términos se regirán e interpretarán de acuerdo con las leyes del país donde se ubica la Plataforma. Cualquier disputa relacionada con estos Términos será sometida a la jurisdicción exclusiva de los tribunales de dicho país.</p>

            <p>Al hacer clic en "Acepto" o al utilizar la Plataforma, usted reconoce que ha leído, entendido y aceptado los Términos y Condiciones aquí presentados.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowTermsModal(false)}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )
    };
  
    function SingUpModal() {
      return (
        <Modal centered show={showSingUpModal} onHide={() => setShowSingUpModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="font-s">Bienvenido a A.R.S Academy</Modal.Title>
          </Modal.Header>
          <Modal.Body className="font-xs">
            <p>¡Tu registro ha sido confirmado con éxito! Ya tienes acceso durante las próximas 24 horas. Explora las herramientas y recursos que nuestra platafoma tiene para ofrecerte.</p>
            <p className="mb-3">Si deseas seguir disfrutando de la aplicación o tienes alguna duda, por favor, contacta con nosotros:</p>
            <ContactInfo />
            <p className="mt-3 mb-4">Gracias por tu interés en nuestra platafoma.</p>
            <p>Con aprecio,</p>
            <p>El equipo de A.R.S Academy</p>
          </Modal.Body>
        </Modal >
      )
    };

    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <TermsModal />
        <SingUpModal />
        <Row className="mb-3">
          <Form.Group className="mb-3" as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Nº de teléfono</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text className="prefix-size ps-1">+34</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Nº de teléfono"
                defaultValue={phoneNumber}
                onChange={handlePhoneChange}
                className={validated ? (phoneValid ? "form-valid" : "form-invalid") : "form-control"}
              />
              {(validated && !phoneValid) && <div className="feedback-invalid">{phoneValidMsg}</div>}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="6">
            <Form.Label>Nombre de usuario</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text className="prefix-size">@</InputGroup.Text>
              <Form.Control required type="text" placeholder="Username" defaultValue={username}
                onChange={(e) => setUsername(e.target.value)} />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="6">
            <Form.Label>Nombre</Form.Label>
            <Form.Control required type="text" placeholder="Nombre" defaultValue={name}
              onChange={(e) => setName(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" as={Col} md="6">
            <Form.Label>Apellido</Form.Label>
            <Form.Control required type="text" placeholder="Apellido" defaultValue={surname}
              onChange={(e) => setSurname(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Check required
              label={
                <>
                  Aceptar{" "}
                  <span className="link" onClick={() => setShowTermsModal(true)}>
                    términos y condiciones
                  </span>
                </>
              }
              feedback="Debes aceptar los términos y condiciones" feedbackType="invalid" />
          </Form.Group>
        </Row>
        <div className="d-flex justify-content-center align-items-center">
          {loading ?
            <Button variant="success" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />Registrarse
            </Button>
            :
            <Button variant="success" type="submit">Registrarse</Button>
          }
        </div>
      </Form>
    );
  };

  function Contact() {
    return (
      <>
        <p className="font-bold">Contáctanos</p>
        <p>Si tienes preguntas o estás interesado en nuestros servicios, no dudes en comunicarte:</p>
        <ContactInfo />
        <p className="mt-4">Responderemos a tu mensaje lo antes posible.</p>
      </>
    );
  };


  return (
    <>
      <Accordion.Item className="mb-3">
        <Accordion.Header>
          <span className="accordion-header">Acceso</span>
        </Accordion.Header>
        <Accordion.Body className="font-xs">
          <Col lg={{ span: 10, offset: 1 }}>
            <Tabs className="mb-3" variant="tabs">
              <Tab eventKey="login" title="Iniciar sesión">
                <LoginForm />
              </Tab>
              <Tab eventKey="singup" title="Registrarse">
                <SignUpForm />
              </Tab>
              <Tab eventKey="contact" title="Contacto">
                <Contact />
              </Tab>
            </Tabs>
          </Col>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};


export default Login;