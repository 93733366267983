
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";


function Footer() {
  return (
    <div className="footer mb-3">
      <img className="footer-img" src="logo.png" />
      <div className="text-center mt-3">
        A.R.S Academy 2024
      </div>
      <div className="text-center">
        Todos los derechos reservados <FontAwesomeIcon icon={faCopyright} />
      </div>
    </div>
  );
};


export default Footer;