import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userStatus: {},
  markeds: [],
  errors: [],
  result: {
    showResult: false,
    score: 0,
    corrects: 0,
    wrongs: 0
  },
};

export const StatusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.userStatus = action.payload;
      const allMarkedQuestionIds = Object.values(action.payload.marked_questions).flat();
      state.markeds = allMarkedQuestionIds;
      const allErrorQuestionIds = Object.values(action.payload.error_questions).flat();
      state.errors = allErrorQuestionIds;
    },
    setMarkQuestion: (state, action) => {
      const { uuid, test_id } = action.payload;
      if (state.markeds.includes(uuid)) {
        state.markeds = state.markeds.filter(_uuid => _uuid !== uuid);
      } else {
        state.markeds.push(uuid);
      }
      if (state.userStatus.marked_questions.hasOwnProperty(test_id)) {
        const index = state.userStatus.marked_questions[test_id].indexOf(uuid);
        if (index > -1) {
          state.userStatus.marked_questions[test_id].splice(index, 1);
        } else {
          state.userStatus.marked_questions[test_id].push(uuid);
        }
      } else {
        state.userStatus.marked_questions[test_id] = [uuid];
      }
    },
    setErrorsQuestion: (state, action) => {
      const { corrects, wrongs } = action.payload.result
      const correctIds = corrects.map(question => question.uuid);
      const wrongsIds = wrongs.map(question => question.uuid);

      correctIds.forEach(question_id => {
        const index = state.errors.indexOf(question_id);
        if (index > -1) {
          state.errors.splice(index, 1);
        }
      });

      wrongsIds.forEach(question_id => {
        if (!state.errors.includes(question_id)) {
          state.errors.push(question_id);
        }
      });

      corrects.forEach(question => {
        const question_id = question["uuid"];
        const test_id = question["test_id"];
        if (state.userStatus.error_questions.hasOwnProperty(test_id)) {
          const index = state.userStatus.error_questions[test_id].indexOf(question_id);
          if (index > -1) {
            state.userStatus.error_questions[test_id].splice(index, 1);
          }
        }
      });

      wrongs.forEach(question => {
        const question_id = question["uuid"];
        const test_id = question["test_id"];

        if (state.userStatus.error_questions.hasOwnProperty(test_id)) {
          if (!state.userStatus.error_questions[test_id].includes(question_id)) {
            state.userStatus.error_questions[test_id].push(question_id);
          }
        } else {
          state.userStatus.error_questions[test_id] = [question_id];
        }
      });
    },
    setShowResult: (state, action) => {
      state.result.showResult = action.payload;
    },
    setResult: (state, action) => {
      state.result = { ...state.result, ...action.payload.result };
      const { result, test_id } = action.payload;
      const { historical_results } = state.userStatus;
      if (historical_results.hasOwnProperty(test_id)) {
        historical_results[test_id].push(result);
      } else {
        historical_results[test_id] = [result];
      }
    },
    setRestartResult: (state) => {
      state.result = { ...initialState.result };
    }
  }
});

export const { setStatus, setMarkQuestion, setErrorsQuestion, setShowResult, setResult, setRestartResult } = StatusSlice.actions;
export const selectStatus = (state) => state.status;
export default StatusSlice.reducer;