import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organization: [],
  alreadyLoaded: false
};

export const OrganizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.organization = action.payload;
      state.alreadyLoaded = true;
    }
  }
});

export const { setOrganization } = OrganizationSlice.actions;
export const selectOrganization = (state) => state.organization;
export default OrganizationSlice.reducer;