import { configureStore } from '@reduxjs/toolkit';
import screenReducer from '../slices/screenSlice';
import configReducer from '../slices/configSlice';
import questionsReducer from '../slices/questionsSlice';
import organizationReducer from '../slices/organizationSlice';
import tokenReducer from '../slices/tokenSlice';
import statusReducer from '../slices/statusSlice';
import readerReducer from '../slices/readerSlice';

export const store = configureStore({
  reducer: {
    screen: screenReducer,
    config: configReducer,
    questions: questionsReducer,
    organization: organizationReducer,
    token: tokenReducer,
    status: statusReducer,
    reader: readerReducer
  },
});
