
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Accordion, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { selectConfig } from "../slices/configSlice";
import { post, get } from "axios";


function Users() {
  const { serverHost } = useSelector(selectConfig);
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(0);

  // Load data
  useEffect(() => {
    const getUsers = () => {
      const url = `${serverHost}/users/list`;
      get(url).then((response) => {
        setUsers(response.data.users);
      }).catch(function (error) {
        console.log(error);
      });
    };
    getUsers();

  }, [serverHost, update]);

  function ListUsers() {
    const handleUpdateUser = (phone) => {
      const url = `${serverHost}/users/update`;
      const userData = { "phone": phone }
      post(url, userData).then((response) => {
        if (response.data.statusCode === 200) {
          setUpdate(update+1);
        } else {
        }
      }).catch(function (error) {
        console.log(error);
      });
    };

    const getMessageUrl = (user) => {
      return `https://api.whatsapp.com/send?phone=34${user.phone}&text=Hola%20${user.username}%2C%0A%0A%C2%A1Nos%20complace%20informarte%20que%20tu%20cuenta%20ha%20sido%20activada%20con%20%C3%A9xito%20en%20nuestra%20plataforma%21%0A%0AAhora%20puedes%20acceder%20a%20todos%20los%20servicios%20disponibles.%20Para%20iniciar%20sesi%C3%B3n%20haz%20clic%20en%20el%20siguiente%20enlace%3A%0A%0Ahttp%3A%2F%2Ftest-app-ars.s3-website-eu-west-1.amazonaws.com%0A%0ASi%20tienes%20alguna%20pregunta%20o%20necesitas%20asistencia%2C%20no%20dudes%20en%20contactarnos.%20Estamos%20aqu%C3%AD%20para%20ayudarte%20a%20aprovechar%20al%20m%C3%A1ximo%20tu%20experiencia%20en%20nuestra%20plataforma.%0A%0A%C2%A1Bienvenido%20y%20gracias%20por%20unirte%20a%20nosotros%21%0A%0AAtentamente%2C%0A%0AARS%20Academy`
    };

    return (
      <Table responsive className="font-xxs">
        <thead>
          <tr>
            <th>@Nombre</th>
            <th className="text-center">Teléfono</th>
            <th className="text-center">Creación</th>
            <th className="text-center">Activado</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.username}</td>
              <td className="text-center"><a className="link" target="_blank" href={getMessageUrl(user)}>{user.phone}<FontAwesomeIcon className="ms-1" icon={faWhatsapp} /></a></td>
              <td className="text-center">{user.created_at}</td>
              <td className="text-center"><FontAwesomeIcon className="clickable" onClick={() => handleUpdateUser(user.phone)} icon={user.activated ? faCheck : faTimes} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  };


  return (
    <Accordion.Item className="mb-3">
      <Accordion.Header>
        <span className="accordion-header">Gestión de usuarios</span>
      </Accordion.Header>
      <Accordion.Body className="no-padding">
        <ListUsers />
      </Accordion.Body>
    </Accordion.Item>
  );
};


export default Users;