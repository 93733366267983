import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from 'jwt-decode';

const initialState = {
  token: null,
  tokenValid: false,
  userInfo: {}
};

// Get config from local storage if exists
const storedToken = localStorage.getItem('token');

export const tokenSlice = createSlice({
  name: "token",
  initialState: { ...initialState, token: storedToken },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.userInfo = jwtDecode(action.payload);
      state.tokenValid = true;
      localStorage.setItem('token', action.payload);
    }
  }
});


export const { setToken } = tokenSlice.actions;
export const selectToken = (state) => state.token;
export default tokenSlice.reducer;