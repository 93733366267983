import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  quizQuestions: [],
  quizPath: [],
  testId: null,
  questionsLoaded: false
};

const storedConfig = JSON.parse(localStorage.getItem('config'));
const numQuestions = storedConfig ? parseInt(storedConfig.numQuestions) : 10;

export const QuestionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
      const rand = Math.floor(Math.random() * (action.payload.length-numQuestions + 1))
      state.quizQuestions = state.questions.slice(rand, rand+numQuestions);
      state.quizPath = ["Test aleatorio"]
      state.questionsLoaded = true;
    },
    setTest: (state, action) => {
      const { test_id, test_path } = action.payload;
      const quiz = state.questions.filter(question => question.test_id === test_id);
      state.testId = test_id;
      state.quizQuestions = quiz;
      state.quizPath = test_path;
      window.scrollTo(0, 0);

    },
    setCustomTest: (state, action) => {
      const { questionIds,  path} = action.payload;
      const filteredQuiz = state.questions.filter(question => questionIds.includes(question.uuid));
      state.testId = path.join("|");
      state.quizQuestions = filteredQuiz;
      state.quizPath = path;
      window.scrollTo(0, 0);

    }
  }
});

export const { setQuestions, setTest, setCustomTest } = QuestionsSlice.actions;
export const selectQuestions = (state) => state.questions;
export default QuestionsSlice.reducer;