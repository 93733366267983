import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showOptions: false,
  defaultPatht: ["", ""]
};

export const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    setToggleOptions: (state) => {
      state.showOptions = !state.showOptions; 
    },
    setDefaultPath: (state, action) => {
      state.defaultPatht = action.payload; 
    }
  }
});

export const { setToggleOptions, setDefaultPath } = screenSlice.actions;
export const selectScreen = (state) => state.screen;
export default screenSlice.reducer;