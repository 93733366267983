
import React, { useState, useEffect } from "react";
import moment from 'moment';
import NoSleep from 'nosleep.js';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Modal, Table, Accordion, Badge } from 'react-bootstrap';
import { selectOrganization } from "../slices/organizationSlice";
import { selectStatus, setRestartResult } from "../slices/statusSlice";
import { selectScreen, setDefaultPath } from "../slices/screenSlice";
import { selectQuestions } from "../slices/questionsSlice";
import { selectPDF, setSeletedPDF } from "../slices/readerSlice";
import { setTest, setCustomTest } from "../slices/questionsSlice";
import { selectConfig } from "../slices/configSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faExclamationTriangle, faThumbsUp, faThumbsDown, faCalendarAlt, faAward, faChartLine, faAsterisk, faFile } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";


function Home() {
  const navigate = useNavigate();
  const noSleep = new NoSleep();
  const { organization } = useSelector(selectOrganization);
  const { userStatus } = useSelector(selectStatus);
  const { defaultPatht } = useSelector(selectScreen);
  const { historical_results, marked_questions, error_questions } = userStatus;
  const { questions, questionsLoaded } = useSelector(selectQuestions);
  const { pdfs, viededPDFS } = useSelector(selectPDF);
  const { numQuestions } = useSelector(selectConfig);
  const [loading, setLoading] = useState(!questionsLoaded);
  const [showModal, setShowModal] = useState(false);
  const [historicalResults, setHistoricalResults] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.speechSynthesis.cancel();
    setLoading(!questionsLoaded);
  }, [questionsLoaded, pdfs]);

  const handleShowHist = (test_id) => {
    const hist = [...historical_results[test_id]].sort((a, b) => b.date - a.date);
    setHistoricalResults(hist);
    setShowModal(true);
  };

  const getRandomQuestions = (questionIds, customNumQuestions = null) => {
    const q = [...questionIds];
    for (let i = q.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [q[i], q[j]] = [q[j], q[i]];
    }

    return q.slice(0, customNumQuestions ? customNumQuestions : numQuestions);
  };

  function TestIcon({ count, bgClass, icon, iconClass, onClick }) {
    return count > 0 && (
      <div onClick={onClick} className="clickable inline-block">
        <Badge className={`ms-2 ${bgClass}`}>{count}</Badge>
        <FontAwesomeIcon className={iconClass} icon={icon} />
      </div>
    );
  };

  function TestTitle(props) {
    const { test, show_hr, nombre_bloque, nombre_tema } = props;
    const showHist = test.test_id in historical_results;
    const numHist = showHist ? historical_results[test.test_id].length : 0;

    const selectTest = () => {
      const test_path = [nombre_bloque, nombre_tema, test.nombre_test];
      const payload = {
        test_id: test.test_id,
        test_path: test_path
      }
      dispatch(setTest(payload));
      dispatch(setRestartResult());
      dispatch(setDefaultPath(test_path));
      navigate("/quiz");
      noSleep.enable();
    };

    return (
      <>
        <p className="clickable inline-block me-2" onClick={selectTest}>{test.nombre_test}</p>
        {showHist &&
          <TestIcon
            count={numHist}
            bgClass="bg-secondary"
            iconClass="font-s ms-1 blue-color"
            icon={faChartLine}
            onClick={() => handleShowHist(test.test_id)}
          />
        }
        {show_hr ? <hr className="hr" /> : <></>}
      </>
    );
  };

  function TestStatus(props) {
    const { tests, nombre_bloque, nombre_tema } = props;
    const testIds = tests.map(test => test.test_id);
    const numMarkeds = testIds.reduce((total, testId) => { return total + (marked_questions[testId] ? marked_questions[testId].length : 0) }, 0);
    const numErrors = testIds.reduce((total, testId) => { return total + (error_questions[testId] ? error_questions[testId].length : 0) }, 0);
    const availablePDFs = pdfs.filter(pdf => pdf.nombre_tema === nombre_tema);
    const isNew = !viededPDFS.includes(nombre_tema);

    const handleMarkedQuiz = () => {
      const questionIds = testIds.reduce((acc, testId) => {
        if (marked_questions[testId]) {
          acc.push(...marked_questions[testId]);
        }
        return acc;
      }, []);
      const path = [nombre_bloque, nombre_tema, "Preguntas guardadas"];
      const payload = {"questionIds": questionIds, "path": path};
      dispatch(setCustomTest(payload));
      dispatch(setDefaultPath(path));
      navigate("/quiz");
      noSleep.enable();
      dispatch(setRestartResult());
    };

    const handleErrorQuiz = () => {
      const questionIds = testIds.reduce((acc, testId) => {
        if (error_questions[testId]) {
          acc.push(...error_questions[testId]);
        }
        return acc;
      }, []);
      const path = [nombre_bloque, nombre_tema, "Preguntas falladas"];
      const payload = {"questionIds": questionIds, "path": path};
      dispatch(setCustomTest(payload));
      dispatch(setDefaultPath(path));
      navigate("/quiz");
      noSleep.enable();
      dispatch(setRestartResult());
    };

    const handlePDF = () => {
      const selectedPDF = availablePDFs[0]
      const path = [selectedPDF.nombre_bloque, ""];
      dispatch(setSeletedPDF(selectedPDF));
      dispatch(setDefaultPath(path));
      navigate("/reader");
      noSleep.enable();
    };

    function PDFIcon() {
      return (
        <>{
          availablePDFs.length > 0 &&
          <div className="ms-1 clickable inline-block">
            <Badge onClick={handlePDF} className={"bg-secondary"}>PDF</Badge>
            {isNew &&
              <FontAwesomeIcon className="link font-xxs mb-1" icon={faAsterisk} />
            }
          </div>
        }
        </>
      );
    };


    function SummaryIcon() {
      return (
        <>{
          availablePDFs.length > 0 &&
          <div className="ms-1 clickable inline-block">
            <Badge onClick={handlePDF} className={"bg-secondary"}><FontAwesomeIcon icon={faFile} /></Badge>
            {isNew &&
              <FontAwesomeIcon className="link font-xxs mb-1" icon={faAsterisk} />
            }
          </div>
        }
        </>
      );
    };
  
    return (
      <div className="inline-block mt-2">
        <PDFIcon />
        {/* <SummaryIcon /> */}
        <TestIcon
          count={numMarkeds}
          bgClass="bg-primary"
          iconClass="text-primary font-xs mb-1"
          icon={faQuestionCircle}
          onClick={handleMarkedQuiz}
        />
        <TestIcon
          count={numErrors}
          bgClass="bg-danger"
          iconClass="text-danger font-xs mb-1"
          icon={faExclamationTriangle}
          onClick={handleErrorQuiz}
        />
      </div>
    );
  };

  function ReviewTema(props) {
    const { tests, nombre_bloque, nombre_tema } = props;
    const testIds = tests.map(test => test.test_id);
    const path = [nombre_bloque, nombre_tema, "Repaso"];
    const test_id = path.join("|");
    const showHist = test_id in historical_results;
    const numHist = showHist ? historical_results[test_id].length : 0;

    const selectTest = () => {
      const questionIds = questions.filter(question => testIds.includes(question.test_id)).map(question => question.uuid);
      const randomQuestionsIds = getRandomQuestions(questionIds);
      const payload = {"questionIds": randomQuestionsIds, "path": path};
      dispatch(setCustomTest(payload));
      dispatch(setDefaultPath(path));
      navigate("/quiz");
      noSleep.enable();
      dispatch(setRestartResult());
    };

    return (
      <>
        <p className="clickable inline" onClick={selectTest}>Repaso {nombre_tema}</p>
        {showHist &&
          <TestIcon
            count={numHist}
            bgClass="bg-secondary"
            iconClass="font-s ms-1 blue-color"
            icon={faChartLine}
            onClick={() => handleShowHist(test_id)}
          />
        }
      </>
    );
  };

  function ReviewBloque(props) {
    const { bloque } = props;
    const testIds = bloque.temas.reduce((acc, tema) => {
      const ids = tema.tests.map(test => test.test_id);
      return acc.concat(ids);
    }, []);
    const path = [bloque.nombre_bloque, 'Repaso'];
    const test_id = path.join("|");
    const showHist = test_id in historical_results;
    const numHist = showHist ? historical_results[test_id].length : 0;

    const selectTest = () => {
      const questionIds = questions.filter(question => testIds.includes(question.test_id)).map(question => question.uuid);
      const randomQuestionsIds = getRandomQuestions(questionIds);
      const payload = {"questionIds": randomQuestionsIds, "path": path}
      dispatch(setCustomTest(payload));
      dispatch(setDefaultPath(path));
      navigate("/quiz");
      noSleep.enable();
      dispatch(setRestartResult());
    };

    return (
      <Accordion.Header className="not-clickable">
        <span className="clickable" onClick={selectTest}>Repaso del bloque</span>
        {showHist &&
          <TestIcon
            count={numHist}
            bgClass="bg-secondary"
            iconClass="font-s ms-1 blue-color"
            icon={faChartLine}
            onClick={() => handleShowHist(test_id)}
          />
        }
      </Accordion.Header>
    );
  };

  function FinalExam() {
    const organizationQuestions = organization.reduce((acc, bloque) => {
      const temasFiltrados = bloque.temas
        .filter(tema => tema.num_questions)
        .map(tema => ({
          num_questions: tema.num_questions,
          test_ids: tema.tests.map(test => test.test_id)
        }));

      return acc.concat(temasFiltrados);
    }, []);
    const path = ["EXÁMENES", "Exámen final"];
    const test_id = path.join("|");
    const showHist = test_id in historical_results;
    const numHist = showHist ? historical_results[test_id].length : 0;

    const selectTest = () => {
      let finalExamQuestions = [];
      organizationQuestions.forEach(value => {
        let questionIds = questions.filter(question => value.test_ids.includes(question.test_id)).map(question => question.uuid);
        finalExamQuestions.push(getRandomQuestions(questionIds, value.num_questions));
      });
      finalExamQuestions = finalExamQuestions.flat();
      const payload = {"questionIds": finalExamQuestions, "path": path};
      dispatch(setCustomTest(payload));
      dispatch(setDefaultPath(path));
      navigate("/quiz");
      noSleep.enable();
      dispatch(setRestartResult());
    };

    return (
      <Accordion.Header className="not-clickable">
        <span className="clickable" onClick={selectTest}>Exámen final</span>
        {showHist &&
          <TestIcon
            count={numHist}
            bgClass="bg-secondary"
            iconClass="font-s ms-1 blue-color"
            icon={faChartLine}
            onClick={() => handleShowHist(test_id)}
          />
        }
      </Accordion.Header>
    );
  };

  function ResultModal() {
    const handleClose = () => {
      setShowModal(false);
    };

    function FormattedDate(timestamp) {
      const formattedDateParts = moment.unix(timestamp).format('DD-MM-YYYY').split('|');
      return (
        <>
          {formattedDateParts.map((part, index) => (
            <React.Fragment key={index}>
              {part}{index < formattedDateParts.length - 1 && <br />}
            </React.Fragment>
          ))}
        </>
      );
    };

    return (
      <Modal
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        onClick={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-s">Histórico de resultados</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center"><FontAwesomeIcon icon={faCalendarAlt} /></th>
                <th className="text-center"><FontAwesomeIcon icon={faAward} /></th>
                <td className="text-center"><FontAwesomeIcon icon={faThumbsUp} /></td>
                <th className="text-center"><FontAwesomeIcon icon={faThumbsDown} /></th>
              </tr>
            </thead>
            <tbody className="font-xxs">
              {historicalResults.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{FormattedDate(item.date)}</td>
                  <td className="text-center">{item.score}/100</td>
                  <td className="text-center">{item.corrects.length}</td>
                  <td className="text-center">{item.wrongs.length}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal >
    )
  };

  return (
    <>
      <ResultModal />
      {loading ? <Loader /> : (
        <Accordion defaultActiveKey={defaultPatht[0]} >
          {organization.map(bloque => (
            <Accordion.Item key={bloque.nombre_bloque} eventKey={`${bloque.nombre_bloque}`} className="mb-3">
              <Accordion.Header>
                <span className="accordion-header">{bloque.nombre_bloque}</span>
              </Accordion.Header>
              <Accordion.Body >
                <Accordion defaultActiveKey={defaultPatht[1]}>
                  {bloque.temas.map(tema => (
                    <Accordion.Item key={tema.nombre_tema} eventKey={`${tema.nombre_tema}`} className="mb-3">
                      <Accordion.Header>
                        <span className="inline-block me-2">{tema.nombre_tema}</span>
                        <TestStatus
                          tests={tema.tests}
                          nombre_bloque={bloque.nombre_bloque}
                          nombre_tema={tema.nombre_tema}
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        {tema.tests.map((test, index) => (
                          <div key={test.test_id}>
                            <TestTitle
                              test={test}
                              nombre_bloque={bloque.nombre_bloque}
                              nombre_tema={tema.nombre_tema}
                              show_hr={(index + 1) < tema.tests.length || bloque.nombre_bloque !== 'EXÁMENES'}
                            />
                          </div>
                        ))}
                        {bloque.nombre_bloque !== 'EXÁMENES' &&
                          <ReviewTema
                            tests={tema.tests}
                            nombre_bloque={bloque.nombre_bloque}
                            nombre_tema={tema.nombre_tema}
                          />
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                  <Accordion.Item eventKey={`${bloque.nombre_bloque}`} className="mb-3">
                    {bloque.nombre_bloque === 'EXÁMENES' ?
                      <FinalExam />
                      :
                      <ReviewBloque bloque={bloque} />
                    }
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
};


export default Home;