import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useSelector } from "react-redux";
import { Card, Modal, Button, Col } from "react-bootstrap";
import { selectConfig } from "../slices/configSlice";
import { selectPDF } from "../slices/readerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import readerDictionary from './reader_dictionary.json';
import {
  faPlayCircle,
  faStepForward,
  faPlay,
  faPause,
  faStepBackward,
  faStop,
  faLongArrowAltUp,
  faGrinWink,
  faChevronRight,
  faFileDownload
} from "@fortawesome/free-solid-svg-icons"


function Reader() {
  const synth = window.speechSynthesis;
  const { selectedPDF, lines, urls } = useSelector(selectPDF);
  const { nombre_bloque, nombre_tema, pdf } = selectedPDF;
  const { narratorVel, fontSize } = useSelector(selectConfig);
  const [indexLine, setIndexLine] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);
  const [upIsVisible, setUpIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const prevPlayingRef = useRef();
  const prevPausedRef = useRef();
  const prevIndexLineRef = useRef();
  const fontSizes = {
    'Pequeño': 'font-xxs',
    'Normal': 'font-xs',
    'Grande': 'font-s'
  }

  const goToLine = (index) => {
    $(".reader .line").removeClass("yellow-background");
    const line = $(`#line${index}`)[0]
    let position = line.getBoundingClientRect();
    $(`#line${index}`).addClass("yellow-background");
    window.scrollTo(position.left, position.top + window.scrollY - 100);
  };

  const getLineText = (index) => {
    let text = $(`#line${index}`).text();

    Object.keys(readerDictionary).sort((a, b) => b.length - a.length).forEach(key => {
      const regex = new RegExp(key, 'g');
      text = text.replace(regex, readerDictionary[key]);
    });

    return text;
  }

  const goStart = () => {
    $(".reader .line").removeClass("yellow-background");
    window.scrollTo(0, 0);
  };

  const handleStop = () => {
    setIndexLine(0);
    setPlaying(false);
    setPaused(false);
    goStart();
  };

  const handleSelectLine = (index) => {
    setPlaying(true);
    setPaused(false);
    setIndexLine(index);
  };

  const play = () => {
    goToLine(indexLine);
    const text = getLineText(indexLine);
    const messageParts = text.split('.').flatMap(line => line.split(':')).filter(l => l);
    let currentIndex = 0;
    const speak = (textToSpeak) => {
      const msg = new SpeechSynthesisUtterance();
      msg.text = textToSpeak;
      const rate = narratorVel === "Normal" ? 1 : parseFloat(narratorVel.replace("x", ""));
      msg.rate = rate + 0.2;
      msg.onend = function () {
        currentIndex++;
        if (currentIndex < messageParts.length) {
          setTimeout(() => {
            speak(messageParts[currentIndex])
          }, 100);
        } else {
          setTimeout(() => {
            setIndexLine(indexLine + 1);
          }, 300);
        }
      };
      speechSynthesis.speak(msg);
    }
    speak(messageParts[0]);
  };

  useEffect(() => {
    if (playing !== prevPlayingRef.current || indexLine !== prevIndexLineRef.current) {
      synth.cancel();
      if (playing) {
        if ((indexLine >= 0) && (indexLine < lines.length) && !paused) {
          play();
        } else if (indexLine >= lines.length) {
          setIndexLine(0);
          setPlaying(false);
          goStart();
        } else if (indexLine <= 0) {
          setIndexLine(0);
        }
      }
    } else if (paused !== prevPausedRef.current) {
      playing && paused ? synth.pause() : synth.resume();
    }

    const adjustRightPosition = () => {
      var parentElement = $('.reader');
      var fixedElement = $('.fixed-bottom-right');
      if (parentElement.length && fixedElement.length) {
        var parentRightEdge = parentElement.offset().left + parentElement.outerWidth();
        var windowRightEdge = $(window).width();
        fixedElement.css('right', windowRightEdge - parentRightEdge + 'px');
      }
    };
    const onScroll = () => {
      setUpIsVisible($(window).scrollTop() > 100);
    };

    adjustRightPosition();
    $(window).on('scroll', onScroll);
    $(window).on('resize', adjustRightPosition);
    prevPlayingRef.current = playing;
    prevIndexLineRef.current = indexLine;
    prevPausedRef.current = paused;

    return () => {

      $(window).off('scroll', onScroll);
      $(window).off('resize', adjustRightPosition);
    };
  }, [playing, indexLine, paused, upIsVisible, showModal, fontSize]);


  function PlayButton() {
    return (
      <Button
        className="btn-msize white-color blue-background top-right"
        onClick={() => setPlaying(true)}>
        <FontAwesomeIcon icon={faPlayCircle} />
      </Button>
    );
  };

  function DownloadPDFButton() {
    return (
      <Button
        bsPrefix="btn-download"
        className="top-right me-5">
        <a className="blue-color" target="_blank" href={urls[pdf]}>
          <FontAwesomeIcon icon={faFileDownload} />
        </a>
      </Button>
    );
  };

  function ControlButtons() {
    return (
      <div className="fixed-bottom-right">
        <Button
          className="btn-msize white-color blue-background float-right"
          onClick={() => setIndexLine(indexLine + 1)}>
          <FontAwesomeIcon icon={faStepForward} />
        </Button>
        {paused ?
          <Button
            className="btn-msize white-color blue-background float-right me-1"
            onClick={() => setPaused(false)}>
            <FontAwesomeIcon icon={faPlay} />
          </Button>
          :
          <Button
            className="btn-msize white-color blue-background float-right me-1"
            onClick={() => setPaused(true)}>
            <FontAwesomeIcon icon={faPause} />
          </Button>
        }
        <Button
          className="btn-msize white-color blue-background float-right me-1"
          onClick={() => setIndexLine(indexLine - 1)}>
          <FontAwesomeIcon icon={faStepBackward} />
        </Button>
        <Button
          className="btn-msize white-color blue-background float-right me-1"
          onClick={handleStop}>
          <FontAwesomeIcon icon={faStop} />
        </Button>
      </div>
    );
  };

  function UpButton() {
    return (
      <div className={`fixed-bottom-right ${playing ? "btn-up":""}`}>
        <Button
          className="btn-msize primary-background float-right"
          onClick={() => window.scrollTo(0, 0)}>
          <FontAwesomeIcon icon={faLongArrowAltUp} />
        </Button>
      </div>
    );
  };

  function Title() {
    return (
      <Col className="no-padding" xs={{ span: 10 }}>
        <div className="quiz mb-3 me-5">
          {nombre_bloque.split(":")[0]}
          <FontAwesomeIcon className="font-xxxs me-2 ms-2" icon={faChevronRight} /> 
          {nombre_tema.split(":")[0]}
          <FontAwesomeIcon className="font-xxxs me-2 ms-2" icon={faChevronRight} /> 
          PDF
        </div>
      </Col>

    )
  };

  function MessageModal() {
    const handleClose = () => {
      setShowModal(false);
    };

    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        onClick={handleClose}
      >
        <Modal.Body className="text-center">
          Pregunta copiada
          <FontAwesomeIcon className="font-sm me-2 ms-2" icon={faGrinWink} />
        </Modal.Body>
      </Modal >
    )
  };

  return (
    <Card className="card">
      <Card.Body className="padding-s">
        <Title />
        <div className="reader">
          {!playing ? <><DownloadPDFButton /><PlayButton /></>: <ControlButtons />}
          {upIsVisible && <UpButton />}
          {lines.map((line, index) => (
            <div className={`line clickable ${fontSizes[fontSize]}`} id={`line${index}`} key={index} onClick={() => handleSelectLine(index)} dangerouslySetInnerHTML={{ __html: line }} />
          ))}
        </div>
        <MessageModal />
      </Card.Body >
    </Card >
  );
};


export default Reader;