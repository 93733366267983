import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faAd, 
faAddressBook, 
faAddressCard, 
faAdjust, 
faAirFreshener, 
faAlignCenter, 
faAlignJustify, 
faAlignLeft, 
faAlignRight, 
faAllergies, 
faAmbulance, 
faAmericanSignLanguageInterpreting, 
faAnchor, 
faAngleDoubleDown, 
faAngleDoubleLeft, 
faAngleDoubleRight, 
faAngleDoubleUp, 
faAngleDown, 
faAngleLeft, 
faAngleRight, 
faAngleUp, 
faAngry, 
faAnkh, 
faAppleAlt, 
faArchive, 
faArchway, 
faArrowAltCircleDown, 
faArrowAltCircleLeft, 
faArrowAltCircleRight, 
faArrowAltCircleUp, 
faArrowCircleDown, 
faArrowCircleLeft, 
faArrowCircleRight, 
faArrowCircleUp, 
faArrowDown, 
faArrowLeft, 
faArrowRight, 
faArrowUp, 
faArrowsAlt, 
faArrowsAltH, 
faArrowsAltV, 
faAssistiveListeningSystems, 
faAsterisk, 
faAt, 
faAtlas, 
faAtom, 
faAudioDescription, 
faAward, 
faBaby, 
faBabyCarriage, 
faBackspace, 
faBackward, 
faBacon, 
faBacteria, 
faBacterium, 
faBahai, 
faBalanceScale, 
faBalanceScaleLeft, 
faBalanceScaleRight, 
faBan, 
faBandAid, 
faBarcode, 
faBars, 
faBaseballBall, 
faBasketballBall, 
faBath, 
faBatteryEmpty, 
faBatteryFull, 
faBatteryHalf, 
faBatteryQuarter, 
faBatteryThreeQuarters, 
faBed, 
faBeer, 
faBell, 
faBellSlash, 
faBezierCurve, 
faBible, 
faBicycle, 
faBiking, 
faBinoculars, 
faBiohazard, 
faBirthdayCake, 
faBlender, 
faBlenderPhone, 
faBlind, 
faBlog, 
faBold, 
faBolt, 
faBomb, 
faBone, 
faBong, 
faBook, 
faBookDead, 
faBookMedical, 
faBookOpen, 
faBookReader, 
faBookmark, 
faBorderAll, 
faBorderNone, 
faBorderStyle, 
faBowlingBall, 
faBox, 
faBoxOpen, 
faBoxTissue, 
faBoxes, 
faBraille, 
faBrain, 
faBreadSlice, 
faBriefcase, 
faBriefcaseMedical, 
faBroadcastTower, 
faBroom, 
faBrush, 
faBug, 
faBuilding, 
faBullhorn, 
faBullseye, 
faBurn, 
faBus, 
faBusAlt, 
faBusinessTime, 
faCalculator, 
faCalendar, 
faCalendarAlt, 
faCalendarCheck, 
faCalendarDay, 
faCalendarMinus, 
faCalendarPlus, 
faCalendarTimes, 
faCalendarWeek, 
faCamera, 
faCameraRetro, 
faCampground, 
faCandyCane, 
faCannabis, 
faCapsules, 
faCar, 
faCarAlt, 
faCarBattery, 
faCarCrash, 
faCarSide, 
faCaravan, 
faCaretDown, 
faCaretLeft, 
faCaretRight, 
faCaretSquareDown, 
faCaretSquareLeft, 
faCaretSquareRight, 
faCaretSquareUp, 
faCaretUp, 
faCarrot, 
faCartArrowDown, 
faCartPlus, 
faCashRegister, 
faCat, 
faCertificate, 
faChair, 
faChalkboard, 
faChalkboardTeacher, 
faChargingStation, 
faChartArea, 
faChartBar, 
faChartLine, 
faChartPie, 
faCheck, 
faCheckCircle, 
faCheckDouble, 
faCheckSquare, 
faCheese, 
faChess, 
faChessBishop, 
faChessBoard, 
faChessKing, 
faChessKnight, 
faChessPawn, 
faChessQueen, 
faChessRook, 
faChevronCircleDown, 
faChevronCircleLeft, 
faChevronCircleRight, 
faChevronCircleUp, 
faChevronDown, 
faChevronLeft, 
faChevronRight, 
faChevronUp, 
faChild, 
faChurch, 
faCircle, 
faCircleNotch, 
faCity, 
faClinicMedical, 
faClipboard, 
faClipboardCheck, 
faClipboardList, 
faClock, 
faClone, 
faClosedCaptioning, 
faCloud, 
faCloudDownloadAlt, 
faCloudMeatball, 
faCloudMoon, 
faCloudMoonRain, 
faCloudRain, 
faCloudShowersHeavy, 
faCloudSun, 
faCloudSunRain, 
faCloudUploadAlt, 
faCocktail, 
faCode, 
faCodeBranch, 
faCoffee, 
faCog, 
faCogs, 
faCoins, 
faColumns, 
faComment, 
faCommentAlt, 
faCommentDollar, 
faCommentDots, 
faCommentMedical, 
faCommentSlash, 
faComments, 
faCommentsDollar, 
faCompactDisc, 
faCompass, 
faCompress, 
faCompressAlt, 
faCompressArrowsAlt, 
faConciergeBell, 
faCookie, 
faCookieBite, 
faCopy, 
faCopyright, 
faCouch, 
faCreditCard, 
faCrop, 
faCropAlt, 
faCross, 
faCrosshairs, 
faCrow, 
faCrown, 
faCrutch, 
faCube, 
faCubes, 
faCut, 
faDatabase, 
faDeaf, 
faDemocrat, 
faDesktop, 
faDharmachakra, 
faDiagnoses, 
faDice, 
faDiceD20, 
faDiceD6, 
faDiceFive, 
faDiceFour, 
faDiceOne, 
faDiceSix, 
faDiceThree, 
faDiceTwo, 
faDigitalTachograph, 
faDirections, 
faDisease, 
faDivide, 
faDizzy, 
faDna, 
faDog, 
faDollarSign, 
faDolly, 
faDollyFlatbed, 
faDonate, 
faDoorClosed, 
faDoorOpen, 
faDotCircle, 
faDove, 
faDownload, 
faDraftingCompass, 
faDragon, 
faDrawPolygon, 
faDrum, 
faDrumSteelpan, 
faDrumstickBite, 
faDumbbell, 
faDumpster, 
faDumpsterFire, 
faDungeon, 
faEdit, 
faEgg, 
faEject, 
faEllipsisH, 
faEllipsisV, 
faEnvelope, 
faEnvelopeOpen, 
faEnvelopeOpenText, 
faEnvelopeSquare, 
faEquals, 
faEraser, 
faEthernet, 
faEuroSign, 
faExchangeAlt, 
faExclamation, 
faExclamationCircle, 
faExclamationTriangle, 
faExpand, 
faExpandAlt, 
faExpandArrowsAlt, 
faExternalLinkAlt, 
faExternalLinkSquareAlt, 
faEye, 
faEyeDropper, 
faEyeSlash, 
faFan, 
faFastBackward, 
faFastForward, 
faFaucet, 
faFax, 
faFeather, 
faFeatherAlt, 
faFemale, 
faFighterJet, 
faFile, 
faFileAlt, 
faFileArchive, 
faFileAudio, 
faFileCode, 
faFileContract, 
faFileCsv, 
faFileDownload, 
faFileExcel, 
faFileExport, 
faFileImage, 
faFileImport, 
faFileInvoice, 
faFileInvoiceDollar, 
faFileMedical, 
faFileMedicalAlt, 
faFilePdf, 
faFilePowerpoint, 
faFilePrescription, 
faFileSignature, 
faFileUpload, 
faFileVideo, 
faFileWord, 
faFill, 
faFillDrip, 
faFilm, 
faFilter, 
faFingerprint, 
faFire, 
faFireAlt, 
faFireExtinguisher, 
faFirstAid, 
faFish, 
faFistRaised, 
faFlag, 
faFlagCheckered, 
faFlagUsa, 
faFlask, 
faFlushed, 
faFolder, 
faFolderMinus, 
faFolderOpen, 
faFolderPlus, 
faFont, 
faFontAwesomeLogoFull, 
faFootballBall, 
faForward, 
faFrog, 
faFrown, 
faFrownOpen, 
faFunnelDollar, 
faFutbol, 
faGamepad, 
faGasPump, 
faGavel, 
faGem, 
faGenderless, 
faGhost, 
faGift, 
faGifts, 
faGlassCheers, 
faGlassMartini, 
faGlassMartiniAlt, 
faGlassWhiskey, 
faGlasses, 
faGlobe, 
faGlobeAfrica, 
faGlobeAmericas, 
faGlobeAsia, 
faGlobeEurope, 
faGolfBall, 
faGopuram, 
faGraduationCap, 
faGreaterThan, 
faGreaterThanEqual, 
faGrimace, 
faGrin, 
faGrinAlt, 
faGrinBeam, 
faGrinBeamSweat, 
faGrinHearts, 
faGrinSquint, 
faGrinSquintTears, 
faGrinStars, 
faGrinTears, 
faGrinTongue, 
faGrinTongueSquint, 
faGrinTongueWink, 
faGrinWink, 
faGripHorizontal, 
faGripLines, 
faGripLinesVertical, 
faGripVertical, 
faGuitar, 
faHSquare, 
faHamburger, 
faHammer, 
faHamsa, 
faHandHolding, 
faHandHoldingHeart, 
faHandHoldingMedical, 
faHandHoldingUsd, 
faHandHoldingWater, 
faHandLizard, 
faHandMiddleFinger, 
faHandPaper, 
faHandPeace, 
faHandPointDown, 
faHandPointLeft, 
faHandPointRight, 
faHandPointUp, 
faHandPointer, 
faHandRock, 
faHandScissors, 
faHandSparkles, 
faHandSpock, 
faHands, 
faHandsHelping, 
faHandsWash, 
faHandshake, 
faHandshakeAltSlash, 
faHandshakeSlash, 
faHanukiah, 
faHardHat, 
faHashtag, 
faHatCowboy, 
faHatCowboySide, 
faHatWizard, 
faHdd, 
faHeadSideCough, 
faHeadSideCoughSlash, 
faHeadSideMask, 
faHeadSideVirus, 
faHeading, 
faHeadphones, 
faHeadphonesAlt, 
faHeadset, 
faHeart, 
faHeartBroken, 
faHeartbeat, 
faHelicopter, 
faHighlighter, 
faHiking, 
faHippo, 
faHistory, 
faHockeyPuck, 
faHollyBerry, 
faHome, 
faHorse, 
faHorseHead, 
faHospital, 
faHospitalAlt, 
faHospitalSymbol, 
faHospitalUser, 
faHotTub, 
faHotdog, 
faHotel, 
faHourglass, 
faHourglassEnd, 
faHourglassHalf, 
faHourglassStart, 
faHouseDamage, 
faHouseUser, 
faHryvnia, 
faICursor, 
faIceCream, 
faIcicles, 
faIcons, 
faIdBadge, 
faIdCard, 
faIdCardAlt, 
faIgloo, 
faImage, 
faImages, 
faInbox, 
faIndent, 
faIndustry, 
faInfinity, 
faInfo, 
faInfoCircle, 
faItalic, 
faJedi, 
faJoint, 
faJournalWhills, 
faKaaba, 
faKey, 
faKeyboard, 
faKhanda, 
faKiss, 
faKissBeam, 
faKissWinkHeart, 
faKiwiBird, 
faLandmark, 
faLanguage, 
faLaptop, 
faLaptopCode, 
faLaptopHouse, 
faLaptopMedical, 
faLaugh, 
faLaughBeam, 
faLaughSquint, 
faLaughWink, 
faLayerGroup, 
faLeaf, 
faLemon, 
faLessThan, 
faLessThanEqual, 
faLevelDownAlt, 
faLevelUpAlt, 
faLifeRing, 
faLightbulb, 
faLink, 
faLiraSign, 
faList, 
faListAlt, 
faListOl, 
faListUl, 
faLocationArrow, 
faLock, 
faLockOpen, 
faLongArrowAltDown, 
faLongArrowAltLeft, 
faLongArrowAltRight, 
faLongArrowAltUp, 
faLowVision, 
faLuggageCart, 
faLungs, 
faLungsVirus, 
faMagic, 
faMagnet, 
faMailBulk, 
faMale, 
faMap, 
faMapMarked, 
faMapMarkedAlt, 
faMapMarker, 
faMapMarkerAlt, 
faMapPin, 
faMapSigns, 
faMarker, 
faMars, 
faMarsDouble, 
faMarsStroke, 
faMarsStrokeH, 
faMarsStrokeV, 
faMask, 
faMedal, 
faMedkit, 
faMeh, 
faMehBlank, 
faMehRollingEyes, 
faMemory, 
faMenorah, 
faMercury, 
faMeteor, 
faMicrochip, 
faMicrophone, 
faMicrophoneAlt, 
faMicrophoneAltSlash, 
faMicrophoneSlash, 
faMicroscope, 
faMinus, 
faMinusCircle, 
faMinusSquare, 
faMitten, 
faMobile, 
faMobileAlt, 
faMoneyBill, 
faMoneyBillAlt, 
faMoneyBillWave, 
faMoneyBillWaveAlt, 
faMoneyCheck, 
faMoneyCheckAlt, 
faMonument, 
faMoon, 
faMortarPestle, 
faMosque, 
faMotorcycle, 
faMountain, 
faMouse, 
faMousePointer, 
faMugHot, 
faMusic, 
faNetworkWired, 
faNeuter, 
faNewspaper, 
faNotEqual, 
faNotesMedical, 
faObjectGroup, 
faObjectUngroup, 
faOilCan, 
faOm, 
faOtter, 
faOutdent, 
faPager, 
faPaintBrush, 
faPaintRoller, 
faPalette, 
faPallet, 
faPaperPlane, 
faPaperclip, 
faParachuteBox, 
faParagraph, 
faParking, 
faPassport, 
faPastafarianism, 
faPaste, 
faPause, 
faPauseCircle, 
faPaw, 
faPeace, 
faPen, 
faPenAlt, 
faPenFancy, 
faPenNib, 
faPenSquare, 
faPencilAlt, 
faPencilRuler, 
faPeopleArrows, 
faPeopleCarry, 
faPepperHot, 
faPercent, 
faPercentage, 
faPersonBooth, 
faPhone, 
faPhoneAlt, 
faPhoneSlash, 
faPhoneSquare, 
faPhoneSquareAlt, 
faPhoneVolume, 
faPhotoVideo, 
faPiggyBank, 
faPills, 
faPizzaSlice, 
faPlaceOfWorship, 
faPlane, 
faPlaneArrival, 
faPlaneDeparture, 
faPlaneSlash, 
faPlay, 
faPlayCircle, 
faPlug, 
faPlus, 
faPlusCircle, 
faPlusSquare, 
faPodcast, 
faPoll, 
faPollH, 
faPoo, 
faPooStorm, 
faPoop, 
faPortrait, 
faPoundSign, 
faPowerOff, 
faPray, 
faPrayingHands, 
faPrescription, 
faPrescriptionBottle, 
faPrescriptionBottleAlt, 
faPrint, 
faProcedures, 
faProjectDiagram, 
faPumpMedical, 
faPumpSoap, 
faPuzzlePiece, 
faQrcode, 
faQuestion, 
faQuestionCircle, 
faQuidditch, 
faQuoteLeft, 
faQuoteRight, 
faQuran, 
faRadiation, 
faRadiationAlt, 
faRainbow, 
faRandom, 
faReceipt, 
faRecordVinyl, 
faRecycle, 
faRedo, 
faRedoAlt, 
faRegistered, 
faRemoveFormat, 
faReply, 
faReplyAll, 
faRepublican, 
faRestroom, 
faRetweet, 
faRibbon, 
faRing, 
faRoad, 
faRobot, 
faRocket, 
faRoute, 
faRss, 
faRssSquare, 
faRubleSign, 
faRuler, 
faRulerCombined, 
faRulerHorizontal, 
faRulerVertical, 
faRunning, 
faRupeeSign, 
faSadCry, 
faSadTear, 
faSatellite, 
faSatelliteDish, 
faSave, 
faSchool, 
faScrewdriver, 
faScroll, 
faSdCard, 
faSearch, 
faSearchDollar, 
faSearchLocation, 
faSearchMinus, 
faSearchPlus, 
faSeedling, 
faServer, 
faShapes, 
faShare, 
faShareAlt, 
faShareAltSquare, 
faShareSquare, 
faShekelSign, 
faShieldAlt, 
faShieldVirus, 
faShip, 
faShippingFast, 
faShoePrints, 
faShoppingBag, 
faShoppingBasket, 
faShoppingCart, 
faShower, 
faShuttleVan, 
faSign, 
faSignInAlt, 
faSignLanguage, 
faSignOutAlt, 
faSignal, 
faSignature, 
faSimCard, 
faSink, 
faSitemap, 
faSkating, 
faSkiing, 
faSkiingNordic, 
faSkull, 
faSkullCrossbones, 
faSlash, 
faSleigh, 
faSlidersH, 
faSmile, 
faSmileBeam, 
faSmileWink, 
faSmog, 
faSmoking, 
faSmokingBan, 
faSms, 
faSnowboarding, 
faSnowflake, 
faSnowman, 
faSnowplow, 
faSoap, 
faSocks, 
faSolarPanel, 
faSort, 
faSortAlphaDown, 
faSortAlphaDownAlt, 
faSortAlphaUp, 
faSortAlphaUpAlt, 
faSortAmountDown, 
faSortAmountDownAlt, 
faSortAmountUp, 
faSortAmountUpAlt, 
faSortDown, 
faSortNumericDown, 
faSortNumericDownAlt, 
faSortNumericUp, 
faSortNumericUpAlt, 
faSortUp, 
faSpa, 
faSpaceShuttle, 
faSpellCheck, 
faSpider, 
faSpinner, 
faSplotch, 
faSprayCan, 
faSquare, 
faSquareFull, 
faSquareRootAlt, 
faStamp, 
faStar, 
faStarAndCrescent, 
faStarHalf, 
faStarHalfAlt, 
faStarOfDavid, 
faStarOfLife, 
faStepBackward, 
faStepForward, 
faStethoscope, 
faStickyNote, 
faStop, 
faStopCircle, 
faStopwatch, 
faStopwatch20, 
faStore, 
faStoreAlt, 
faStoreAltSlash, 
faStoreSlash, 
faStream, 
faStreetView, 
faStrikethrough, 
faStroopwafel, 
faSubscript, 
faSubway, 
faSuitcase, 
faSuitcaseRolling, 
faSun, 
faSuperscript, 
faSurprise, 
faSwatchbook, 
faSwimmer, 
faSwimmingPool, 
faSynagogue, 
faSync, 
faSyncAlt, 
faSyringe, 
faTable, 
faTableTennis, 
faTablet, 
faTabletAlt, 
faTablets, 
faTachometerAlt, 
faTag, 
faTags, 
faTape, 
faTasks, 
faTaxi, 
faTeeth, 
faTeethOpen, 
faTemperatureHigh, 
faTemperatureLow, 
faTenge, 
faTerminal, 
faTextHeight, 
faTextWidth, 
faTh, 
faThLarge, 
faThList, 
faTheaterMasks, 
faThermometer, 
faThermometerEmpty, 
faThermometerFull, 
faThermometerHalf, 
faThermometerQuarter, 
faThermometerThreeQuarters, 
faThumbsDown, 
faThumbsUp, 
faThumbtack, 
faTicketAlt, 
faTimes, 
faTimesCircle, 
faTint, 
faTintSlash, 
faTired, 
faToggleOff, 
faToggleOn, 
faToilet, 
faToiletPaper, 
faToiletPaperSlash, 
faToolbox, 
faTools, 
faTooth, 
faTorah, 
faToriiGate, 
faTractor, 
faTrademark, 
faTrafficLight, 
faTrailer, 
faTrain, 
faTram, 
faTransgender, 
faTransgenderAlt, 
faTrash, 
faTrashAlt, 
faTrashRestore, 
faTrashRestoreAlt, 
faTree, 
faTrophy, 
faTruck, 
faTruckLoading, 
faTruckMonster, 
faTruckMoving, 
faTruckPickup, 
faTshirt, 
faTty, 
faTv, 
faUmbrella, 
faUmbrellaBeach, 
faUnderline, 
faUndo, 
faUndoAlt, 
faUniversalAccess, 
faUniversity, 
faUnlink, 
faUnlock, 
faUnlockAlt, 
faUpload, 
faUser, 
faUserAlt, 
faUserAltSlash, 
faUserAstronaut, 
faUserCheck, 
faUserCircle, 
faUserClock, 
faUserCog, 
faUserEdit, 
faUserFriends, 
faUserGraduate, 
faUserInjured, 
faUserLock, 
faUserMd, 
faUserMinus, 
faUserNinja, 
faUserNurse, 
faUserPlus, 
faUserSecret, 
faUserShield, 
faUserSlash, 
faUserTag, 
faUserTie, 
faUserTimes, 
faUsers, 
faUsersCog, 
faUsersSlash, 
faUtensilSpoon, 
faUtensils, 
faVectorSquare, 
faVenus, 
faVenusDouble, 
faVenusMars, 
faVest, 
faVestPatches, 
faVial, 
faVials, 
faVideo, 
faVideoSlash, 
faVihara, 
faVirus, 
faVirusSlash, 
faViruses, 
faVoicemail, 
faVolleyballBall, 
faVolumeDown, 
faVolumeMute, 
faVolumeOff, 
faVolumeUp, 
faVoteYea, 
faVrCardboard, 
faWalking, 
faWallet, 
faWarehouse, 
faWater, 
faWaveSquare, 
faWeight, 
faWeightHanging, 
faWheelchair, 
faWifi, 
faWind, 
faWindowClose, 
faWindowMaximize, 
faWindowMinimize, 
faWindowRestore, 
faWineBottle, 
faWineGlass, 
faWineGlassAlt, 
faWonSign, 
faWrench, 
faXRay, 
faYenSign, 
faYinYang
} from "@fortawesome/free-solid-svg-icons"


function Help() {
  return (
    <>

<div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAd} />
            <br />
            <p className="font_small">faAd</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAddressBook} />
            <br />
            <p className="font_small">faAddressBook</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAddressCard} />
            <br />
            <p className="font_small">faAddressCard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAdjust} />
            <br />
            <p className="font_small">faAdjust</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAirFreshener} />
            <br />
            <p className="font_small">faAirFreshener</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAlignCenter} />
            <br />
            <p className="font_small">faAlignCenter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAlignJustify} />
            <br />
            <p className="font_small">faAlignJustify</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAlignLeft} />
            <br />
            <p className="font_small">faAlignLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAlignRight} />
            <br />
            <p className="font_small">faAlignRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAllergies} />
            <br />
            <p className="font_small">faAllergies</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAmbulance} />
            <br />
            <p className="font_small">faAmbulance</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAmericanSignLanguageInterpreting} />
            <br />
            <p className="font_small">faAmericanSignLanguageInterpreting</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAnchor} />
            <br />
            <p className="font_small">faAnchor</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleDoubleDown} />
            <br />
            <p className="font_small">faAngleDoubleDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
            <br />
            <p className="font_small">faAngleDoubleLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            <br />
            <p className="font_small">faAngleDoubleRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleDoubleUp} />
            <br />
            <p className="font_small">faAngleDoubleUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleDown} />
            <br />
            <p className="font_small">faAngleDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleLeft} />
            <br />
            <p className="font_small">faAngleLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleRight} />
            <br />
            <p className="font_small">faAngleRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngleUp} />
            <br />
            <p className="font_small">faAngleUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAngry} />
            <br />
            <p className="font_small">faAngry</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAnkh} />
            <br />
            <p className="font_small">faAnkh</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAppleAlt} />
            <br />
            <p className="font_small">faAppleAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArchive} />
            <br />
            <p className="font_small">faArchive</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArchway} />
            <br />
            <p className="font_small">faArchway</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
            <br />
            <p className="font_small">faArrowAltCircleDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            <br />
            <p className="font_small">faArrowAltCircleLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
            <br />
            <p className="font_small">faArrowAltCircleRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
            <br />
            <p className="font_small">faArrowAltCircleUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowCircleDown} />
            <br />
            <p className="font_small">faArrowCircleDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowCircleLeft} />
            <br />
            <p className="font_small">faArrowCircleLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowCircleRight} />
            <br />
            <p className="font_small">faArrowCircleRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowCircleUp} />
            <br />
            <p className="font_small">faArrowCircleUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowDown} />
            <br />
            <p className="font_small">faArrowDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowLeft} />
            <br />
            <p className="font_small">faArrowLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowRight} />
            <br />
            <p className="font_small">faArrowRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowUp} />
            <br />
            <p className="font_small">faArrowUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowsAlt} />
            <br />
            <p className="font_small">faArrowsAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowsAltH} />
            <br />
            <p className="font_small">faArrowsAltH</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faArrowsAltV} />
            <br />
            <p className="font_small">faArrowsAltV</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAssistiveListeningSystems} />
            <br />
            <p className="font_small">faAssistiveListeningSystems</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAsterisk} />
            <br />
            <p className="font_small">faAsterisk</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAt} />
            <br />
            <p className="font_small">faAt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAtlas} />
            <br />
            <p className="font_small">faAtlas</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAtom} />
            <br />
            <p className="font_small">faAtom</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAudioDescription} />
            <br />
            <p className="font_small">faAudioDescription</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faAward} />
            <br />
            <p className="font_small">faAward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBaby} />
            <br />
            <p className="font_small">faBaby</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBabyCarriage} />
            <br />
            <p className="font_small">faBabyCarriage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBackspace} />
            <br />
            <p className="font_small">faBackspace</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBackward} />
            <br />
            <p className="font_small">faBackward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBacon} />
            <br />
            <p className="font_small">faBacon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBacteria} />
            <br />
            <p className="font_small">faBacteria</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBacterium} />
            <br />
            <p className="font_small">faBacterium</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBahai} />
            <br />
            <p className="font_small">faBahai</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBalanceScale} />
            <br />
            <p className="font_small">faBalanceScale</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBalanceScaleLeft} />
            <br />
            <p className="font_small">faBalanceScaleLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBalanceScaleRight} />
            <br />
            <p className="font_small">faBalanceScaleRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBan} />
            <br />
            <p className="font_small">faBan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBandAid} />
            <br />
            <p className="font_small">faBandAid</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBarcode} />
            <br />
            <p className="font_small">faBarcode</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBars} />
            <br />
            <p className="font_small">faBars</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBaseballBall} />
            <br />
            <p className="font_small">faBaseballBall</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBasketballBall} />
            <br />
            <p className="font_small">faBasketballBall</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBath} />
            <br />
            <p className="font_small">faBath</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBatteryEmpty} />
            <br />
            <p className="font_small">faBatteryEmpty</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBatteryFull} />
            <br />
            <p className="font_small">faBatteryFull</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBatteryHalf} />
            <br />
            <p className="font_small">faBatteryHalf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBatteryQuarter} />
            <br />
            <p className="font_small">faBatteryQuarter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBatteryThreeQuarters} />
            <br />
            <p className="font_small">faBatteryThreeQuarters</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBed} />
            <br />
            <p className="font_small">faBed</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBeer} />
            <br />
            <p className="font_small">faBeer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBell} />
            <br />
            <p className="font_small">faBell</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBellSlash} />
            <br />
            <p className="font_small">faBellSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBezierCurve} />
            <br />
            <p className="font_small">faBezierCurve</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBible} />
            <br />
            <p className="font_small">faBible</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBicycle} />
            <br />
            <p className="font_small">faBicycle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBiking} />
            <br />
            <p className="font_small">faBiking</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBinoculars} />
            <br />
            <p className="font_small">faBinoculars</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBiohazard} />
            <br />
            <p className="font_small">faBiohazard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBirthdayCake} />
            <br />
            <p className="font_small">faBirthdayCake</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBlender} />
            <br />
            <p className="font_small">faBlender</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBlenderPhone} />
            <br />
            <p className="font_small">faBlenderPhone</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBlind} />
            <br />
            <p className="font_small">faBlind</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBlog} />
            <br />
            <p className="font_small">faBlog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBold} />
            <br />
            <p className="font_small">faBold</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBolt} />
            <br />
            <p className="font_small">faBolt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBomb} />
            <br />
            <p className="font_small">faBomb</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBone} />
            <br />
            <p className="font_small">faBone</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBong} />
            <br />
            <p className="font_small">faBong</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBook} />
            <br />
            <p className="font_small">faBook</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBookDead} />
            <br />
            <p className="font_small">faBookDead</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBookMedical} />
            <br />
            <p className="font_small">faBookMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBookOpen} />
            <br />
            <p className="font_small">faBookOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBookReader} />
            <br />
            <p className="font_small">faBookReader</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBookmark} />
            <br />
            <p className="font_small">faBookmark</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBorderAll} />
            <br />
            <p className="font_small">faBorderAll</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBorderNone} />
            <br />
            <p className="font_small">faBorderNone</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBorderStyle} />
            <br />
            <p className="font_small">faBorderStyle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBowlingBall} />
            <br />
            <p className="font_small">faBowlingBall</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBox} />
            <br />
            <p className="font_small">faBox</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBoxOpen} />
            <br />
            <p className="font_small">faBoxOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBoxTissue} />
            <br />
            <p className="font_small">faBoxTissue</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBoxes} />
            <br />
            <p className="font_small">faBoxes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBraille} />
            <br />
            <p className="font_small">faBraille</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBrain} />
            <br />
            <p className="font_small">faBrain</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBreadSlice} />
            <br />
            <p className="font_small">faBreadSlice</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBriefcase} />
            <br />
            <p className="font_small">faBriefcase</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBriefcaseMedical} />
            <br />
            <p className="font_small">faBriefcaseMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBroadcastTower} />
            <br />
            <p className="font_small">faBroadcastTower</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBroom} />
            <br />
            <p className="font_small">faBroom</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBrush} />
            <br />
            <p className="font_small">faBrush</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBug} />
            <br />
            <p className="font_small">faBug</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBuilding} />
            <br />
            <p className="font_small">faBuilding</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBullhorn} />
            <br />
            <p className="font_small">faBullhorn</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBullseye} />
            <br />
            <p className="font_small">faBullseye</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBurn} />
            <br />
            <p className="font_small">faBurn</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBus} />
            <br />
            <p className="font_small">faBus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBusAlt} />
            <br />
            <p className="font_small">faBusAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faBusinessTime} />
            <br />
            <p className="font_small">faBusinessTime</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalculator} />
            <br />
            <p className="font_small">faCalculator</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendar} />
            <br />
            <p className="font_small">faCalendar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarAlt} />
            <br />
            <p className="font_small">faCalendarAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarCheck} />
            <br />
            <p className="font_small">faCalendarCheck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarDay} />
            <br />
            <p className="font_small">faCalendarDay</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarMinus} />
            <br />
            <p className="font_small">faCalendarMinus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarPlus} />
            <br />
            <p className="font_small">faCalendarPlus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarTimes} />
            <br />
            <p className="font_small">faCalendarTimes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCalendarWeek} />
            <br />
            <p className="font_small">faCalendarWeek</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCamera} />
            <br />
            <p className="font_small">faCamera</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCameraRetro} />
            <br />
            <p className="font_small">faCameraRetro</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCampground} />
            <br />
            <p className="font_small">faCampground</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCandyCane} />
            <br />
            <p className="font_small">faCandyCane</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCannabis} />
            <br />
            <p className="font_small">faCannabis</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCapsules} />
            <br />
            <p className="font_small">faCapsules</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCar} />
            <br />
            <p className="font_small">faCar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCarAlt} />
            <br />
            <p className="font_small">faCarAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCarBattery} />
            <br />
            <p className="font_small">faCarBattery</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCarCrash} />
            <br />
            <p className="font_small">faCarCrash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCarSide} />
            <br />
            <p className="font_small">faCarSide</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaravan} />
            <br />
            <p className="font_small">faCaravan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretDown} />
            <br />
            <p className="font_small">faCaretDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretLeft} />
            <br />
            <p className="font_small">faCaretLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretRight} />
            <br />
            <p className="font_small">faCaretRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretSquareDown} />
            <br />
            <p className="font_small">faCaretSquareDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretSquareLeft} />
            <br />
            <p className="font_small">faCaretSquareLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretSquareRight} />
            <br />
            <p className="font_small">faCaretSquareRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretSquareUp} />
            <br />
            <p className="font_small">faCaretSquareUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCaretUp} />
            <br />
            <p className="font_small">faCaretUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCarrot} />
            <br />
            <p className="font_small">faCarrot</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCartArrowDown} />
            <br />
            <p className="font_small">faCartArrowDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCartPlus} />
            <br />
            <p className="font_small">faCartPlus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCashRegister} />
            <br />
            <p className="font_small">faCashRegister</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCat} />
            <br />
            <p className="font_small">faCat</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCertificate} />
            <br />
            <p className="font_small">faCertificate</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChair} />
            <br />
            <p className="font_small">faChair</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChalkboard} />
            <br />
            <p className="font_small">faChalkboard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChalkboardTeacher} />
            <br />
            <p className="font_small">faChalkboardTeacher</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChargingStation} />
            <br />
            <p className="font_small">faChargingStation</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChartArea} />
            <br />
            <p className="font_small">faChartArea</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChartBar} />
            <br />
            <p className="font_small">faChartBar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChartLine} />
            <br />
            <p className="font_small">faChartLine</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChartPie} />
            <br />
            <p className="font_small">faChartPie</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCheck} />
            <br />
            <p className="font_small">faCheck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCheckCircle} />
            <br />
            <p className="font_small">faCheckCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCheckDouble} />
            <br />
            <p className="font_small">faCheckDouble</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCheckSquare} />
            <br />
            <p className="font_small">faCheckSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCheese} />
            <br />
            <p className="font_small">faCheese</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChess} />
            <br />
            <p className="font_small">faChess</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessBishop} />
            <br />
            <p className="font_small">faChessBishop</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessBoard} />
            <br />
            <p className="font_small">faChessBoard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessKing} />
            <br />
            <p className="font_small">faChessKing</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessKnight} />
            <br />
            <p className="font_small">faChessKnight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessPawn} />
            <br />
            <p className="font_small">faChessPawn</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessQueen} />
            <br />
            <p className="font_small">faChessQueen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChessRook} />
            <br />
            <p className="font_small">faChessRook</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronCircleDown} />
            <br />
            <p className="font_small">faChevronCircleDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronCircleLeft} />
            <br />
            <p className="font_small">faChevronCircleLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronCircleRight} />
            <br />
            <p className="font_small">faChevronCircleRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronCircleUp} />
            <br />
            <p className="font_small">faChevronCircleUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronDown} />
            <br />
            <p className="font_small">faChevronDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronLeft} />
            <br />
            <p className="font_small">faChevronLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronRight} />
            <br />
            <p className="font_small">faChevronRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChevronUp} />
            <br />
            <p className="font_small">faChevronUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChild} />
            <br />
            <p className="font_small">faChild</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faChurch} />
            <br />
            <p className="font_small">faChurch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCircle} />
            <br />
            <p className="font_small">faCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCircleNotch} />
            <br />
            <p className="font_small">faCircleNotch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCity} />
            <br />
            <p className="font_small">faCity</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClinicMedical} />
            <br />
            <p className="font_small">faClinicMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClipboard} />
            <br />
            <p className="font_small">faClipboard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClipboardCheck} />
            <br />
            <p className="font_small">faClipboardCheck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClipboardList} />
            <br />
            <p className="font_small">faClipboardList</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClock} />
            <br />
            <p className="font_small">faClock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClone} />
            <br />
            <p className="font_small">faClone</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faClosedCaptioning} />
            <br />
            <p className="font_small">faClosedCaptioning</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloud} />
            <br />
            <p className="font_small">faCloud</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudDownloadAlt} />
            <br />
            <p className="font_small">faCloudDownloadAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudMeatball} />
            <br />
            <p className="font_small">faCloudMeatball</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudMoon} />
            <br />
            <p className="font_small">faCloudMoon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudMoonRain} />
            <br />
            <p className="font_small">faCloudMoonRain</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudRain} />
            <br />
            <p className="font_small">faCloudRain</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudShowersHeavy} />
            <br />
            <p className="font_small">faCloudShowersHeavy</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudSun} />
            <br />
            <p className="font_small">faCloudSun</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudSunRain} />
            <br />
            <p className="font_small">faCloudSunRain</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCloudUploadAlt} />
            <br />
            <p className="font_small">faCloudUploadAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCocktail} />
            <br />
            <p className="font_small">faCocktail</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCode} />
            <br />
            <p className="font_small">faCode</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCodeBranch} />
            <br />
            <p className="font_small">faCodeBranch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCoffee} />
            <br />
            <p className="font_small">faCoffee</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCog} />
            <br />
            <p className="font_small">faCog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCogs} />
            <br />
            <p className="font_small">faCogs</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCoins} />
            <br />
            <p className="font_small">faCoins</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faColumns} />
            <br />
            <p className="font_small">faColumns</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faComment} />
            <br />
            <p className="font_small">faComment</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCommentAlt} />
            <br />
            <p className="font_small">faCommentAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCommentDollar} />
            <br />
            <p className="font_small">faCommentDollar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCommentDots} />
            <br />
            <p className="font_small">faCommentDots</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCommentMedical} />
            <br />
            <p className="font_small">faCommentMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCommentSlash} />
            <br />
            <p className="font_small">faCommentSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faComments} />
            <br />
            <p className="font_small">faComments</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCommentsDollar} />
            <br />
            <p className="font_small">faCommentsDollar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCompactDisc} />
            <br />
            <p className="font_small">faCompactDisc</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCompass} />
            <br />
            <p className="font_small">faCompass</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCompress} />
            <br />
            <p className="font_small">faCompress</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCompressAlt} />
            <br />
            <p className="font_small">faCompressAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCompressArrowsAlt} />
            <br />
            <p className="font_small">faCompressArrowsAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faConciergeBell} />
            <br />
            <p className="font_small">faConciergeBell</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCookie} />
            <br />
            <p className="font_small">faCookie</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCookieBite} />
            <br />
            <p className="font_small">faCookieBite</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCopy} />
            <br />
            <p className="font_small">faCopy</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCopyright} />
            <br />
            <p className="font_small">faCopyright</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCouch} />
            <br />
            <p className="font_small">faCouch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCreditCard} />
            <br />
            <p className="font_small">faCreditCard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCrop} />
            <br />
            <p className="font_small">faCrop</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCropAlt} />
            <br />
            <p className="font_small">faCropAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCross} />
            <br />
            <p className="font_small">faCross</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCrosshairs} />
            <br />
            <p className="font_small">faCrosshairs</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCrow} />
            <br />
            <p className="font_small">faCrow</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCrown} />
            <br />
            <p className="font_small">faCrown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCrutch} />
            <br />
            <p className="font_small">faCrutch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCube} />
            <br />
            <p className="font_small">faCube</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCubes} />
            <br />
            <p className="font_small">faCubes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faCut} />
            <br />
            <p className="font_small">faCut</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDatabase} />
            <br />
            <p className="font_small">faDatabase</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDeaf} />
            <br />
            <p className="font_small">faDeaf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDemocrat} />
            <br />
            <p className="font_small">faDemocrat</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDesktop} />
            <br />
            <p className="font_small">faDesktop</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDharmachakra} />
            <br />
            <p className="font_small">faDharmachakra</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiagnoses} />
            <br />
            <p className="font_small">faDiagnoses</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDice} />
            <br />
            <p className="font_small">faDice</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceD20} />
            <br />
            <p className="font_small">faDiceD20</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceD6} />
            <br />
            <p className="font_small">faDiceD6</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceFive} />
            <br />
            <p className="font_small">faDiceFive</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceFour} />
            <br />
            <p className="font_small">faDiceFour</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceOne} />
            <br />
            <p className="font_small">faDiceOne</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceSix} />
            <br />
            <p className="font_small">faDiceSix</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceThree} />
            <br />
            <p className="font_small">faDiceThree</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDiceTwo} />
            <br />
            <p className="font_small">faDiceTwo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDigitalTachograph} />
            <br />
            <p className="font_small">faDigitalTachograph</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDirections} />
            <br />
            <p className="font_small">faDirections</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDisease} />
            <br />
            <p className="font_small">faDisease</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDivide} />
            <br />
            <p className="font_small">faDivide</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDizzy} />
            <br />
            <p className="font_small">faDizzy</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDna} />
            <br />
            <p className="font_small">faDna</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDog} />
            <br />
            <p className="font_small">faDog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDollarSign} />
            <br />
            <p className="font_small">faDollarSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDolly} />
            <br />
            <p className="font_small">faDolly</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDollyFlatbed} />
            <br />
            <p className="font_small">faDollyFlatbed</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDonate} />
            <br />
            <p className="font_small">faDonate</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDoorClosed} />
            <br />
            <p className="font_small">faDoorClosed</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDoorOpen} />
            <br />
            <p className="font_small">faDoorOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDotCircle} />
            <br />
            <p className="font_small">faDotCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDove} />
            <br />
            <p className="font_small">faDove</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDownload} />
            <br />
            <p className="font_small">faDownload</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDraftingCompass} />
            <br />
            <p className="font_small">faDraftingCompass</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDragon} />
            <br />
            <p className="font_small">faDragon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDrawPolygon} />
            <br />
            <p className="font_small">faDrawPolygon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDrum} />
            <br />
            <p className="font_small">faDrum</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDrumSteelpan} />
            <br />
            <p className="font_small">faDrumSteelpan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDrumstickBite} />
            <br />
            <p className="font_small">faDrumstickBite</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDumbbell} />
            <br />
            <p className="font_small">faDumbbell</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDumpster} />
            <br />
            <p className="font_small">faDumpster</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDumpsterFire} />
            <br />
            <p className="font_small">faDumpsterFire</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faDungeon} />
            <br />
            <p className="font_small">faDungeon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEdit} />
            <br />
            <p className="font_small">faEdit</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEgg} />
            <br />
            <p className="font_small">faEgg</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEject} />
            <br />
            <p className="font_small">faEject</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEllipsisH} />
            <br />
            <p className="font_small">faEllipsisH</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEllipsisV} />
            <br />
            <p className="font_small">faEllipsisV</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEnvelope} />
            <br />
            <p className="font_small">faEnvelope</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEnvelopeOpen} />
            <br />
            <p className="font_small">faEnvelopeOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
            <br />
            <p className="font_small">faEnvelopeOpenText</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEnvelopeSquare} />
            <br />
            <p className="font_small">faEnvelopeSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEquals} />
            <br />
            <p className="font_small">faEquals</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEraser} />
            <br />
            <p className="font_small">faEraser</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEthernet} />
            <br />
            <p className="font_small">faEthernet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEuroSign} />
            <br />
            <p className="font_small">faEuroSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExchangeAlt} />
            <br />
            <p className="font_small">faExchangeAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExclamation} />
            <br />
            <p className="font_small">faExclamation</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExclamationCircle} />
            <br />
            <p className="font_small">faExclamationCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <br />
            <p className="font_small">faExclamationTriangle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExpand} />
            <br />
            <p className="font_small">faExpand</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExpandAlt} />
            <br />
            <p className="font_small">faExpandAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExpandArrowsAlt} />
            <br />
            <p className="font_small">faExpandArrowsAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
            <br />
            <p className="font_small">faExternalLinkAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            <br />
            <p className="font_small">faExternalLinkSquareAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEye} />
            <br />
            <p className="font_small">faEye</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEyeDropper} />
            <br />
            <p className="font_small">faEyeDropper</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faEyeSlash} />
            <br />
            <p className="font_small">faEyeSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFan} />
            <br />
            <p className="font_small">faFan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFastBackward} />
            <br />
            <p className="font_small">faFastBackward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFastForward} />
            <br />
            <p className="font_small">faFastForward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFaucet} />
            <br />
            <p className="font_small">faFaucet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFax} />
            <br />
            <p className="font_small">faFax</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFeather} />
            <br />
            <p className="font_small">faFeather</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFeatherAlt} />
            <br />
            <p className="font_small">faFeatherAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFemale} />
            <br />
            <p className="font_small">faFemale</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFighterJet} />
            <br />
            <p className="font_small">faFighterJet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFile} />
            <br />
            <p className="font_small">faFile</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileAlt} />
            <br />
            <p className="font_small">faFileAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileArchive} />
            <br />
            <p className="font_small">faFileArchive</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileAudio} />
            <br />
            <p className="font_small">faFileAudio</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileCode} />
            <br />
            <p className="font_small">faFileCode</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileContract} />
            <br />
            <p className="font_small">faFileContract</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileCsv} />
            <br />
            <p className="font_small">faFileCsv</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileDownload} />
            <br />
            <p className="font_small">faFileDownload</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileExcel} />
            <br />
            <p className="font_small">faFileExcel</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileExport} />
            <br />
            <p className="font_small">faFileExport</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileImage} />
            <br />
            <p className="font_small">faFileImage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileImport} />
            <br />
            <p className="font_small">faFileImport</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileInvoice} />
            <br />
            <p className="font_small">faFileInvoice</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
            <br />
            <p className="font_small">faFileInvoiceDollar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileMedical} />
            <br />
            <p className="font_small">faFileMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileMedicalAlt} />
            <br />
            <p className="font_small">faFileMedicalAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFilePdf} />
            <br />
            <p className="font_small">faFilePdf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFilePowerpoint} />
            <br />
            <p className="font_small">faFilePowerpoint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFilePrescription} />
            <br />
            <p className="font_small">faFilePrescription</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileSignature} />
            <br />
            <p className="font_small">faFileSignature</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileUpload} />
            <br />
            <p className="font_small">faFileUpload</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileVideo} />
            <br />
            <p className="font_small">faFileVideo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFileWord} />
            <br />
            <p className="font_small">faFileWord</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFill} />
            <br />
            <p className="font_small">faFill</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFillDrip} />
            <br />
            <p className="font_small">faFillDrip</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFilm} />
            <br />
            <p className="font_small">faFilm</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFilter} />
            <br />
            <p className="font_small">faFilter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFingerprint} />
            <br />
            <p className="font_small">faFingerprint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFire} />
            <br />
            <p className="font_small">faFire</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFireAlt} />
            <br />
            <p className="font_small">faFireAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFireExtinguisher} />
            <br />
            <p className="font_small">faFireExtinguisher</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFirstAid} />
            <br />
            <p className="font_small">faFirstAid</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFish} />
            <br />
            <p className="font_small">faFish</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFistRaised} />
            <br />
            <p className="font_small">faFistRaised</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFlag} />
            <br />
            <p className="font_small">faFlag</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <br />
            <p className="font_small">faFlagCheckered</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFlagUsa} />
            <br />
            <p className="font_small">faFlagUsa</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFlask} />
            <br />
            <p className="font_small">faFlask</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFlushed} />
            <br />
            <p className="font_small">faFlushed</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFolder} />
            <br />
            <p className="font_small">faFolder</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFolderMinus} />
            <br />
            <p className="font_small">faFolderMinus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFolderOpen} />
            <br />
            <p className="font_small">faFolderOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFolderPlus} />
            <br />
            <p className="font_small">faFolderPlus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFont} />
            <br />
            <p className="font_small">faFont</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFontAwesomeLogoFull} />
            <br />
            <p className="font_small">faFontAwesomeLogoFull</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFootballBall} />
            <br />
            <p className="font_small">faFootballBall</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faForward} />
            <br />
            <p className="font_small">faForward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFrog} />
            <br />
            <p className="font_small">faFrog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFrown} />
            <br />
            <p className="font_small">faFrown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFrownOpen} />
            <br />
            <p className="font_small">faFrownOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFunnelDollar} />
            <br />
            <p className="font_small">faFunnelDollar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faFutbol} />
            <br />
            <p className="font_small">faFutbol</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGamepad} />
            <br />
            <p className="font_small">faGamepad</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGasPump} />
            <br />
            <p className="font_small">faGasPump</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGavel} />
            <br />
            <p className="font_small">faGavel</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGem} />
            <br />
            <p className="font_small">faGem</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGenderless} />
            <br />
            <p className="font_small">faGenderless</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGhost} />
            <br />
            <p className="font_small">faGhost</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGift} />
            <br />
            <p className="font_small">faGift</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGifts} />
            <br />
            <p className="font_small">faGifts</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlassCheers} />
            <br />
            <p className="font_small">faGlassCheers</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlassMartini} />
            <br />
            <p className="font_small">faGlassMartini</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlassMartiniAlt} />
            <br />
            <p className="font_small">faGlassMartiniAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlassWhiskey} />
            <br />
            <p className="font_small">faGlassWhiskey</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlasses} />
            <br />
            <p className="font_small">faGlasses</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlobe} />
            <br />
            <p className="font_small">faGlobe</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlobeAfrica} />
            <br />
            <p className="font_small">faGlobeAfrica</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlobeAmericas} />
            <br />
            <p className="font_small">faGlobeAmericas</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlobeAsia} />
            <br />
            <p className="font_small">faGlobeAsia</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGlobeEurope} />
            <br />
            <p className="font_small">faGlobeEurope</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGolfBall} />
            <br />
            <p className="font_small">faGolfBall</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGopuram} />
            <br />
            <p className="font_small">faGopuram</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGraduationCap} />
            <br />
            <p className="font_small">faGraduationCap</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGreaterThan} />
            <br />
            <p className="font_small">faGreaterThan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGreaterThanEqual} />
            <br />
            <p className="font_small">faGreaterThanEqual</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrimace} />
            <br />
            <p className="font_small">faGrimace</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrin} />
            <br />
            <p className="font_small">faGrin</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinAlt} />
            <br />
            <p className="font_small">faGrinAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinBeam} />
            <br />
            <p className="font_small">faGrinBeam</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinBeamSweat} />
            <br />
            <p className="font_small">faGrinBeamSweat</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinHearts} />
            <br />
            <p className="font_small">faGrinHearts</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinSquint} />
            <br />
            <p className="font_small">faGrinSquint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinSquintTears} />
            <br />
            <p className="font_small">faGrinSquintTears</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinStars} />
            <br />
            <p className="font_small">faGrinStars</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinTears} />
            <br />
            <p className="font_small">faGrinTears</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinTongue} />
            <br />
            <p className="font_small">faGrinTongue</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinTongueSquint} />
            <br />
            <p className="font_small">faGrinTongueSquint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinTongueWink} />
            <br />
            <p className="font_small">faGrinTongueWink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGrinWink} />
            <br />
            <p className="font_small">faGrinWink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGripHorizontal} />
            <br />
            <p className="font_small">faGripHorizontal</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGripLines} />
            <br />
            <p className="font_small">faGripLines</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGripLinesVertical} />
            <br />
            <p className="font_small">faGripLinesVertical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGripVertical} />
            <br />
            <p className="font_small">faGripVertical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faGuitar} />
            <br />
            <p className="font_small">faGuitar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHSquare} />
            <br />
            <p className="font_small">faHSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHamburger} />
            <br />
            <p className="font_small">faHamburger</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHammer} />
            <br />
            <p className="font_small">faHammer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHamsa} />
            <br />
            <p className="font_small">faHamsa</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandHolding} />
            <br />
            <p className="font_small">faHandHolding</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandHoldingHeart} />
            <br />
            <p className="font_small">faHandHoldingHeart</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandHoldingMedical} />
            <br />
            <p className="font_small">faHandHoldingMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandHoldingUsd} />
            <br />
            <p className="font_small">faHandHoldingUsd</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandHoldingWater} />
            <br />
            <p className="font_small">faHandHoldingWater</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandLizard} />
            <br />
            <p className="font_small">faHandLizard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandMiddleFinger} />
            <br />
            <p className="font_small">faHandMiddleFinger</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPaper} />
            <br />
            <p className="font_small">faHandPaper</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPeace} />
            <br />
            <p className="font_small">faHandPeace</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPointDown} />
            <br />
            <p className="font_small">faHandPointDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPointLeft} />
            <br />
            <p className="font_small">faHandPointLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPointRight} />
            <br />
            <p className="font_small">faHandPointRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPointUp} />
            <br />
            <p className="font_small">faHandPointUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandPointer} />
            <br />
            <p className="font_small">faHandPointer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandRock} />
            <br />
            <p className="font_small">faHandRock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandScissors} />
            <br />
            <p className="font_small">faHandScissors</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandSparkles} />
            <br />
            <p className="font_small">faHandSparkles</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandSpock} />
            <br />
            <p className="font_small">faHandSpock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHands} />
            <br />
            <p className="font_small">faHands</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandsHelping} />
            <br />
            <p className="font_small">faHandsHelping</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandsWash} />
            <br />
            <p className="font_small">faHandsWash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandshake} />
            <br />
            <p className="font_small">faHandshake</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandshakeAltSlash} />
            <br />
            <p className="font_small">faHandshakeAltSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHandshakeSlash} />
            <br />
            <p className="font_small">faHandshakeSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHanukiah} />
            <br />
            <p className="font_small">faHanukiah</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHardHat} />
            <br />
            <p className="font_small">faHardHat</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHashtag} />
            <br />
            <p className="font_small">faHashtag</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHatCowboy} />
            <br />
            <p className="font_small">faHatCowboy</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHatCowboySide} />
            <br />
            <p className="font_small">faHatCowboySide</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHatWizard} />
            <br />
            <p className="font_small">faHatWizard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHdd} />
            <br />
            <p className="font_small">faHdd</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadSideCough} />
            <br />
            <p className="font_small">faHeadSideCough</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadSideCoughSlash} />
            <br />
            <p className="font_small">faHeadSideCoughSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadSideMask} />
            <br />
            <p className="font_small">faHeadSideMask</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadSideVirus} />
            <br />
            <p className="font_small">faHeadSideVirus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeading} />
            <br />
            <p className="font_small">faHeading</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadphones} />
            <br />
            <p className="font_small">faHeadphones</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadphonesAlt} />
            <br />
            <p className="font_small">faHeadphonesAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeadset} />
            <br />
            <p className="font_small">faHeadset</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeart} />
            <br />
            <p className="font_small">faHeart</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeartBroken} />
            <br />
            <p className="font_small">faHeartBroken</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHeartbeat} />
            <br />
            <p className="font_small">faHeartbeat</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHelicopter} />
            <br />
            <p className="font_small">faHelicopter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHighlighter} />
            <br />
            <p className="font_small">faHighlighter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHiking} />
            <br />
            <p className="font_small">faHiking</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHippo} />
            <br />
            <p className="font_small">faHippo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHistory} />
            <br />
            <p className="font_small">faHistory</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHockeyPuck} />
            <br />
            <p className="font_small">faHockeyPuck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHollyBerry} />
            <br />
            <p className="font_small">faHollyBerry</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHome} />
            <br />
            <p className="font_small">faHome</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHorse} />
            <br />
            <p className="font_small">faHorse</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHorseHead} />
            <br />
            <p className="font_small">faHorseHead</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHospital} />
            <br />
            <p className="font_small">faHospital</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHospitalAlt} />
            <br />
            <p className="font_small">faHospitalAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHospitalSymbol} />
            <br />
            <p className="font_small">faHospitalSymbol</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHospitalUser} />
            <br />
            <p className="font_small">faHospitalUser</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHotTub} />
            <br />
            <p className="font_small">faHotTub</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHotdog} />
            <br />
            <p className="font_small">faHotdog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHotel} />
            <br />
            <p className="font_small">faHotel</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHourglass} />
            <br />
            <p className="font_small">faHourglass</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHourglassEnd} />
            <br />
            <p className="font_small">faHourglassEnd</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHourglassHalf} />
            <br />
            <p className="font_small">faHourglassHalf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHourglassStart} />
            <br />
            <p className="font_small">faHourglassStart</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHouseDamage} />
            <br />
            <p className="font_small">faHouseDamage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHouseUser} />
            <br />
            <p className="font_small">faHouseUser</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faHryvnia} />
            <br />
            <p className="font_small">faHryvnia</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faICursor} />
            <br />
            <p className="font_small">faICursor</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIceCream} />
            <br />
            <p className="font_small">faIceCream</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIcicles} />
            <br />
            <p className="font_small">faIcicles</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIcons} />
            <br />
            <p className="font_small">faIcons</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIdBadge} />
            <br />
            <p className="font_small">faIdBadge</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIdCard} />
            <br />
            <p className="font_small">faIdCard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIdCardAlt} />
            <br />
            <p className="font_small">faIdCardAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIgloo} />
            <br />
            <p className="font_small">faIgloo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faImage} />
            <br />
            <p className="font_small">faImage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faImages} />
            <br />
            <p className="font_small">faImages</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faInbox} />
            <br />
            <p className="font_small">faInbox</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIndent} />
            <br />
            <p className="font_small">faIndent</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faIndustry} />
            <br />
            <p className="font_small">faIndustry</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faInfinity} />
            <br />
            <p className="font_small">faInfinity</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faInfo} />
            <br />
            <p className="font_small">faInfo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faInfoCircle} />
            <br />
            <p className="font_small">faInfoCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faItalic} />
            <br />
            <p className="font_small">faItalic</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faJedi} />
            <br />
            <p className="font_small">faJedi</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faJoint} />
            <br />
            <p className="font_small">faJoint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faJournalWhills} />
            <br />
            <p className="font_small">faJournalWhills</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKaaba} />
            <br />
            <p className="font_small">faKaaba</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKey} />
            <br />
            <p className="font_small">faKey</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKeyboard} />
            <br />
            <p className="font_small">faKeyboard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKhanda} />
            <br />
            <p className="font_small">faKhanda</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKiss} />
            <br />
            <p className="font_small">faKiss</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKissBeam} />
            <br />
            <p className="font_small">faKissBeam</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKissWinkHeart} />
            <br />
            <p className="font_small">faKissWinkHeart</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faKiwiBird} />
            <br />
            <p className="font_small">faKiwiBird</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLandmark} />
            <br />
            <p className="font_small">faLandmark</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLanguage} />
            <br />
            <p className="font_small">faLanguage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaptop} />
            <br />
            <p className="font_small">faLaptop</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaptopCode} />
            <br />
            <p className="font_small">faLaptopCode</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaptopHouse} />
            <br />
            <p className="font_small">faLaptopHouse</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaptopMedical} />
            <br />
            <p className="font_small">faLaptopMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaugh} />
            <br />
            <p className="font_small">faLaugh</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaughBeam} />
            <br />
            <p className="font_small">faLaughBeam</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaughSquint} />
            <br />
            <p className="font_small">faLaughSquint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLaughWink} />
            <br />
            <p className="font_small">faLaughWink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLayerGroup} />
            <br />
            <p className="font_small">faLayerGroup</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLeaf} />
            <br />
            <p className="font_small">faLeaf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLemon} />
            <br />
            <p className="font_small">faLemon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLessThan} />
            <br />
            <p className="font_small">faLessThan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLessThanEqual} />
            <br />
            <p className="font_small">faLessThanEqual</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLevelDownAlt} />
            <br />
            <p className="font_small">faLevelDownAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLevelUpAlt} />
            <br />
            <p className="font_small">faLevelUpAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLifeRing} />
            <br />
            <p className="font_small">faLifeRing</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLightbulb} />
            <br />
            <p className="font_small">faLightbulb</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLink} />
            <br />
            <p className="font_small">faLink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLiraSign} />
            <br />
            <p className="font_small">faLiraSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faList} />
            <br />
            <p className="font_small">faList</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faListAlt} />
            <br />
            <p className="font_small">faListAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faListOl} />
            <br />
            <p className="font_small">faListOl</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faListUl} />
            <br />
            <p className="font_small">faListUl</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLocationArrow} />
            <br />
            <p className="font_small">faLocationArrow</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLock} />
            <br />
            <p className="font_small">faLock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLockOpen} />
            <br />
            <p className="font_small">faLockOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLongArrowAltDown} />
            <br />
            <p className="font_small">faLongArrowAltDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <br />
            <p className="font_small">faLongArrowAltLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLongArrowAltRight} />
            <br />
            <p className="font_small">faLongArrowAltRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLongArrowAltUp} />
            <br />
            <p className="font_small">faLongArrowAltUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLowVision} />
            <br />
            <p className="font_small">faLowVision</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLuggageCart} />
            <br />
            <p className="font_small">faLuggageCart</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLungs} />
            <br />
            <p className="font_small">faLungs</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faLungsVirus} />
            <br />
            <p className="font_small">faLungsVirus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMagic} />
            <br />
            <p className="font_small">faMagic</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMagnet} />
            <br />
            <p className="font_small">faMagnet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMailBulk} />
            <br />
            <p className="font_small">faMailBulk</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMale} />
            <br />
            <p className="font_small">faMale</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMap} />
            <br />
            <p className="font_small">faMap</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMapMarked} />
            <br />
            <p className="font_small">faMapMarked</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMapMarkedAlt} />
            <br />
            <p className="font_small">faMapMarkedAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMapMarker} />
            <br />
            <p className="font_small">faMapMarker</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <br />
            <p className="font_small">faMapMarkerAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMapPin} />
            <br />
            <p className="font_small">faMapPin</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMapSigns} />
            <br />
            <p className="font_small">faMapSigns</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMarker} />
            <br />
            <p className="font_small">faMarker</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMars} />
            <br />
            <p className="font_small">faMars</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMarsDouble} />
            <br />
            <p className="font_small">faMarsDouble</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMarsStroke} />
            <br />
            <p className="font_small">faMarsStroke</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMarsStrokeH} />
            <br />
            <p className="font_small">faMarsStrokeH</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMarsStrokeV} />
            <br />
            <p className="font_small">faMarsStrokeV</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMask} />
            <br />
            <p className="font_small">faMask</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMedal} />
            <br />
            <p className="font_small">faMedal</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMedkit} />
            <br />
            <p className="font_small">faMedkit</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMeh} />
            <br />
            <p className="font_small">faMeh</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMehBlank} />
            <br />
            <p className="font_small">faMehBlank</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMehRollingEyes} />
            <br />
            <p className="font_small">faMehRollingEyes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMemory} />
            <br />
            <p className="font_small">faMemory</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMenorah} />
            <br />
            <p className="font_small">faMenorah</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMercury} />
            <br />
            <p className="font_small">faMercury</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMeteor} />
            <br />
            <p className="font_small">faMeteor</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMicrochip} />
            <br />
            <p className="font_small">faMicrochip</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMicrophone} />
            <br />
            <p className="font_small">faMicrophone</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMicrophoneAlt} />
            <br />
            <p className="font_small">faMicrophoneAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMicrophoneAltSlash} />
            <br />
            <p className="font_small">faMicrophoneAltSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMicrophoneSlash} />
            <br />
            <p className="font_small">faMicrophoneSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMicroscope} />
            <br />
            <p className="font_small">faMicroscope</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMinus} />
            <br />
            <p className="font_small">faMinus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMinusCircle} />
            <br />
            <p className="font_small">faMinusCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMinusSquare} />
            <br />
            <p className="font_small">faMinusSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMitten} />
            <br />
            <p className="font_small">faMitten</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMobile} />
            <br />
            <p className="font_small">faMobile</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMobileAlt} />
            <br />
            <p className="font_small">faMobileAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoneyBill} />
            <br />
            <p className="font_small">faMoneyBill</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoneyBillAlt} />
            <br />
            <p className="font_small">faMoneyBillAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoneyBillWave} />
            <br />
            <p className="font_small">faMoneyBillWave</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
            <br />
            <p className="font_small">faMoneyBillWaveAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoneyCheck} />
            <br />
            <p className="font_small">faMoneyCheck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoneyCheckAlt} />
            <br />
            <p className="font_small">faMoneyCheckAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMonument} />
            <br />
            <p className="font_small">faMonument</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMoon} />
            <br />
            <p className="font_small">faMoon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMortarPestle} />
            <br />
            <p className="font_small">faMortarPestle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMosque} />
            <br />
            <p className="font_small">faMosque</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMotorcycle} />
            <br />
            <p className="font_small">faMotorcycle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMountain} />
            <br />
            <p className="font_small">faMountain</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMouse} />
            <br />
            <p className="font_small">faMouse</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMousePointer} />
            <br />
            <p className="font_small">faMousePointer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMugHot} />
            <br />
            <p className="font_small">faMugHot</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faMusic} />
            <br />
            <p className="font_small">faMusic</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faNetworkWired} />
            <br />
            <p className="font_small">faNetworkWired</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faNeuter} />
            <br />
            <p className="font_small">faNeuter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faNewspaper} />
            <br />
            <p className="font_small">faNewspaper</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faNotEqual} />
            <br />
            <p className="font_small">faNotEqual</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faNotesMedical} />
            <br />
            <p className="font_small">faNotesMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faObjectGroup} />
            <br />
            <p className="font_small">faObjectGroup</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faObjectUngroup} />
            <br />
            <p className="font_small">faObjectUngroup</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faOilCan} />
            <br />
            <p className="font_small">faOilCan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faOm} />
            <br />
            <p className="font_small">faOm</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faOtter} />
            <br />
            <p className="font_small">faOtter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faOutdent} />
            <br />
            <p className="font_small">faOutdent</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPager} />
            <br />
            <p className="font_small">faPager</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPaintBrush} />
            <br />
            <p className="font_small">faPaintBrush</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPaintRoller} />
            <br />
            <p className="font_small">faPaintRoller</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPalette} />
            <br />
            <p className="font_small">faPalette</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPallet} />
            <br />
            <p className="font_small">faPallet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPaperPlane} />
            <br />
            <p className="font_small">faPaperPlane</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPaperclip} />
            <br />
            <p className="font_small">faPaperclip</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faParachuteBox} />
            <br />
            <p className="font_small">faParachuteBox</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faParagraph} />
            <br />
            <p className="font_small">faParagraph</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faParking} />
            <br />
            <p className="font_small">faParking</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPassport} />
            <br />
            <p className="font_small">faPassport</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPastafarianism} />
            <br />
            <p className="font_small">faPastafarianism</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPaste} />
            <br />
            <p className="font_small">faPaste</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPause} />
            <br />
            <p className="font_small">faPause</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPauseCircle} />
            <br />
            <p className="font_small">faPauseCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPaw} />
            <br />
            <p className="font_small">faPaw</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPeace} />
            <br />
            <p className="font_small">faPeace</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPen} />
            <br />
            <p className="font_small">faPen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPenAlt} />
            <br />
            <p className="font_small">faPenAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPenFancy} />
            <br />
            <p className="font_small">faPenFancy</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPenNib} />
            <br />
            <p className="font_small">faPenNib</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPenSquare} />
            <br />
            <p className="font_small">faPenSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPencilAlt} />
            <br />
            <p className="font_small">faPencilAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPencilRuler} />
            <br />
            <p className="font_small">faPencilRuler</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPeopleArrows} />
            <br />
            <p className="font_small">faPeopleArrows</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPeopleCarry} />
            <br />
            <p className="font_small">faPeopleCarry</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPepperHot} />
            <br />
            <p className="font_small">faPepperHot</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPercent} />
            <br />
            <p className="font_small">faPercent</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPercentage} />
            <br />
            <p className="font_small">faPercentage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPersonBooth} />
            <br />
            <p className="font_small">faPersonBooth</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhone} />
            <br />
            <p className="font_small">faPhone</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhoneAlt} />
            <br />
            <p className="font_small">faPhoneAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhoneSlash} />
            <br />
            <p className="font_small">faPhoneSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhoneSquare} />
            <br />
            <p className="font_small">faPhoneSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhoneSquareAlt} />
            <br />
            <p className="font_small">faPhoneSquareAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhoneVolume} />
            <br />
            <p className="font_small">faPhoneVolume</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPhotoVideo} />
            <br />
            <p className="font_small">faPhotoVideo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPiggyBank} />
            <br />
            <p className="font_small">faPiggyBank</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPills} />
            <br />
            <p className="font_small">faPills</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPizzaSlice} />
            <br />
            <p className="font_small">faPizzaSlice</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlaceOfWorship} />
            <br />
            <p className="font_small">faPlaceOfWorship</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlane} />
            <br />
            <p className="font_small">faPlane</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlaneArrival} />
            <br />
            <p className="font_small">faPlaneArrival</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlaneDeparture} />
            <br />
            <p className="font_small">faPlaneDeparture</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlaneSlash} />
            <br />
            <p className="font_small">faPlaneSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlay} />
            <br />
            <p className="font_small">faPlay</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlayCircle} />
            <br />
            <p className="font_small">faPlayCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlug} />
            <br />
            <p className="font_small">faPlug</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlus} />
            <br />
            <p className="font_small">faPlus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlusCircle} />
            <br />
            <p className="font_small">faPlusCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPlusSquare} />
            <br />
            <p className="font_small">faPlusSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPodcast} />
            <br />
            <p className="font_small">faPodcast</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPoll} />
            <br />
            <p className="font_small">faPoll</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPollH} />
            <br />
            <p className="font_small">faPollH</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPoo} />
            <br />
            <p className="font_small">faPoo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPooStorm} />
            <br />
            <p className="font_small">faPooStorm</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPoop} />
            <br />
            <p className="font_small">faPoop</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPortrait} />
            <br />
            <p className="font_small">faPortrait</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPoundSign} />
            <br />
            <p className="font_small">faPoundSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPowerOff} />
            <br />
            <p className="font_small">faPowerOff</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPray} />
            <br />
            <p className="font_small">faPray</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPrayingHands} />
            <br />
            <p className="font_small">faPrayingHands</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPrescription} />
            <br />
            <p className="font_small">faPrescription</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPrescriptionBottle} />
            <br />
            <p className="font_small">faPrescriptionBottle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPrescriptionBottleAlt} />
            <br />
            <p className="font_small">faPrescriptionBottleAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPrint} />
            <br />
            <p className="font_small">faPrint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faProcedures} />
            <br />
            <p className="font_small">faProcedures</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faProjectDiagram} />
            <br />
            <p className="font_small">faProjectDiagram</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPumpMedical} />
            <br />
            <p className="font_small">faPumpMedical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPumpSoap} />
            <br />
            <p className="font_small">faPumpSoap</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faPuzzlePiece} />
            <br />
            <p className="font_small">faPuzzlePiece</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQrcode} />
            <br />
            <p className="font_small">faQrcode</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQuestion} />
            <br />
            <p className="font_small">faQuestion</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQuestionCircle} />
            <br />
            <p className="font_small">faQuestionCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQuidditch} />
            <br />
            <p className="font_small">faQuidditch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQuoteLeft} />
            <br />
            <p className="font_small">faQuoteLeft</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQuoteRight} />
            <br />
            <p className="font_small">faQuoteRight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faQuran} />
            <br />
            <p className="font_small">faQuran</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRadiation} />
            <br />
            <p className="font_small">faRadiation</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRadiationAlt} />
            <br />
            <p className="font_small">faRadiationAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRainbow} />
            <br />
            <p className="font_small">faRainbow</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRandom} />
            <br />
            <p className="font_small">faRandom</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faReceipt} />
            <br />
            <p className="font_small">faReceipt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRecordVinyl} />
            <br />
            <p className="font_small">faRecordVinyl</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRecycle} />
            <br />
            <p className="font_small">faRecycle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRedo} />
            <br />
            <p className="font_small">faRedo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRedoAlt} />
            <br />
            <p className="font_small">faRedoAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRegistered} />
            <br />
            <p className="font_small">faRegistered</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRemoveFormat} />
            <br />
            <p className="font_small">faRemoveFormat</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faReply} />
            <br />
            <p className="font_small">faReply</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faReplyAll} />
            <br />
            <p className="font_small">faReplyAll</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRepublican} />
            <br />
            <p className="font_small">faRepublican</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRestroom} />
            <br />
            <p className="font_small">faRestroom</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRetweet} />
            <br />
            <p className="font_small">faRetweet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRibbon} />
            <br />
            <p className="font_small">faRibbon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRing} />
            <br />
            <p className="font_small">faRing</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRoad} />
            <br />
            <p className="font_small">faRoad</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRobot} />
            <br />
            <p className="font_small">faRobot</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRocket} />
            <br />
            <p className="font_small">faRocket</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRoute} />
            <br />
            <p className="font_small">faRoute</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRss} />
            <br />
            <p className="font_small">faRss</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRssSquare} />
            <br />
            <p className="font_small">faRssSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRubleSign} />
            <br />
            <p className="font_small">faRubleSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRuler} />
            <br />
            <p className="font_small">faRuler</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRulerCombined} />
            <br />
            <p className="font_small">faRulerCombined</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRulerHorizontal} />
            <br />
            <p className="font_small">faRulerHorizontal</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRulerVertical} />
            <br />
            <p className="font_small">faRulerVertical</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRunning} />
            <br />
            <p className="font_small">faRunning</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faRupeeSign} />
            <br />
            <p className="font_small">faRupeeSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSadCry} />
            <br />
            <p className="font_small">faSadCry</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSadTear} />
            <br />
            <p className="font_small">faSadTear</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSatellite} />
            <br />
            <p className="font_small">faSatellite</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSatelliteDish} />
            <br />
            <p className="font_small">faSatelliteDish</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSave} />
            <br />
            <p className="font_small">faSave</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSchool} />
            <br />
            <p className="font_small">faSchool</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faScrewdriver} />
            <br />
            <p className="font_small">faScrewdriver</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faScroll} />
            <br />
            <p className="font_small">faScroll</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSdCard} />
            <br />
            <p className="font_small">faSdCard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSearch} />
            <br />
            <p className="font_small">faSearch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSearchDollar} />
            <br />
            <p className="font_small">faSearchDollar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSearchLocation} />
            <br />
            <p className="font_small">faSearchLocation</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSearchMinus} />
            <br />
            <p className="font_small">faSearchMinus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSearchPlus} />
            <br />
            <p className="font_small">faSearchPlus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSeedling} />
            <br />
            <p className="font_small">faSeedling</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faServer} />
            <br />
            <p className="font_small">faServer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShapes} />
            <br />
            <p className="font_small">faShapes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShare} />
            <br />
            <p className="font_small">faShare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShareAlt} />
            <br />
            <p className="font_small">faShareAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShareAltSquare} />
            <br />
            <p className="font_small">faShareAltSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShareSquare} />
            <br />
            <p className="font_small">faShareSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShekelSign} />
            <br />
            <p className="font_small">faShekelSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShieldAlt} />
            <br />
            <p className="font_small">faShieldAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShieldVirus} />
            <br />
            <p className="font_small">faShieldVirus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShip} />
            <br />
            <p className="font_small">faShip</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShippingFast} />
            <br />
            <p className="font_small">faShippingFast</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShoePrints} />
            <br />
            <p className="font_small">faShoePrints</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShoppingBag} />
            <br />
            <p className="font_small">faShoppingBag</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShoppingBasket} />
            <br />
            <p className="font_small">faShoppingBasket</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShoppingCart} />
            <br />
            <p className="font_small">faShoppingCart</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShower} />
            <br />
            <p className="font_small">faShower</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faShuttleVan} />
            <br />
            <p className="font_small">faShuttleVan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSign} />
            <br />
            <p className="font_small">faSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSignInAlt} />
            <br />
            <p className="font_small">faSignInAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSignLanguage} />
            <br />
            <p className="font_small">faSignLanguage</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSignOutAlt} />
            <br />
            <p className="font_small">faSignOutAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSignal} />
            <br />
            <p className="font_small">faSignal</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSignature} />
            <br />
            <p className="font_small">faSignature</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSimCard} />
            <br />
            <p className="font_small">faSimCard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSink} />
            <br />
            <p className="font_small">faSink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSitemap} />
            <br />
            <p className="font_small">faSitemap</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSkating} />
            <br />
            <p className="font_small">faSkating</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSkiing} />
            <br />
            <p className="font_small">faSkiing</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSkiingNordic} />
            <br />
            <p className="font_small">faSkiingNordic</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSkull} />
            <br />
            <p className="font_small">faSkull</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSkullCrossbones} />
            <br />
            <p className="font_small">faSkullCrossbones</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSlash} />
            <br />
            <p className="font_small">faSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSleigh} />
            <br />
            <p className="font_small">faSleigh</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSlidersH} />
            <br />
            <p className="font_small">faSlidersH</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSmile} />
            <br />
            <p className="font_small">faSmile</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSmileBeam} />
            <br />
            <p className="font_small">faSmileBeam</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSmileWink} />
            <br />
            <p className="font_small">faSmileWink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSmog} />
            <br />
            <p className="font_small">faSmog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSmoking} />
            <br />
            <p className="font_small">faSmoking</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSmokingBan} />
            <br />
            <p className="font_small">faSmokingBan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSms} />
            <br />
            <p className="font_small">faSms</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSnowboarding} />
            <br />
            <p className="font_small">faSnowboarding</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSnowflake} />
            <br />
            <p className="font_small">faSnowflake</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSnowman} />
            <br />
            <p className="font_small">faSnowman</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSnowplow} />
            <br />
            <p className="font_small">faSnowplow</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSoap} />
            <br />
            <p className="font_small">faSoap</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSocks} />
            <br />
            <p className="font_small">faSocks</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSolarPanel} />
            <br />
            <p className="font_small">faSolarPanel</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSort} />
            <br />
            <p className="font_small">faSort</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAlphaDown} />
            <br />
            <p className="font_small">faSortAlphaDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAlphaDownAlt} />
            <br />
            <p className="font_small">faSortAlphaDownAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAlphaUp} />
            <br />
            <p className="font_small">faSortAlphaUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAlphaUpAlt} />
            <br />
            <p className="font_small">faSortAlphaUpAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAmountDown} />
            <br />
            <p className="font_small">faSortAmountDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAmountDownAlt} />
            <br />
            <p className="font_small">faSortAmountDownAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAmountUp} />
            <br />
            <p className="font_small">faSortAmountUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortAmountUpAlt} />
            <br />
            <p className="font_small">faSortAmountUpAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortDown} />
            <br />
            <p className="font_small">faSortDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortNumericDown} />
            <br />
            <p className="font_small">faSortNumericDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortNumericDownAlt} />
            <br />
            <p className="font_small">faSortNumericDownAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortNumericUp} />
            <br />
            <p className="font_small">faSortNumericUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortNumericUpAlt} />
            <br />
            <p className="font_small">faSortNumericUpAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSortUp} />
            <br />
            <p className="font_small">faSortUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSpa} />
            <br />
            <p className="font_small">faSpa</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSpaceShuttle} />
            <br />
            <p className="font_small">faSpaceShuttle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSpellCheck} />
            <br />
            <p className="font_small">faSpellCheck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSpider} />
            <br />
            <p className="font_small">faSpider</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSpinner} />
            <br />
            <p className="font_small">faSpinner</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSplotch} />
            <br />
            <p className="font_small">faSplotch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSprayCan} />
            <br />
            <p className="font_small">faSprayCan</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSquare} />
            <br />
            <p className="font_small">faSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSquareFull} />
            <br />
            <p className="font_small">faSquareFull</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSquareRootAlt} />
            <br />
            <p className="font_small">faSquareRootAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStamp} />
            <br />
            <p className="font_small">faStamp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStar} />
            <br />
            <p className="font_small">faStar</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStarAndCrescent} />
            <br />
            <p className="font_small">faStarAndCrescent</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStarHalf} />
            <br />
            <p className="font_small">faStarHalf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStarHalfAlt} />
            <br />
            <p className="font_small">faStarHalfAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStarOfDavid} />
            <br />
            <p className="font_small">faStarOfDavid</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStarOfLife} />
            <br />
            <p className="font_small">faStarOfLife</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStepBackward} />
            <br />
            <p className="font_small">faStepBackward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStepForward} />
            <br />
            <p className="font_small">faStepForward</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStethoscope} />
            <br />
            <p className="font_small">faStethoscope</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStickyNote} />
            <br />
            <p className="font_small">faStickyNote</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStop} />
            <br />
            <p className="font_small">faStop</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStopCircle} />
            <br />
            <p className="font_small">faStopCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStopwatch} />
            <br />
            <p className="font_small">faStopwatch</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStopwatch20} />
            <br />
            <p className="font_small">faStopwatch20</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStore} />
            <br />
            <p className="font_small">faStore</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStoreAlt} />
            <br />
            <p className="font_small">faStoreAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStoreAltSlash} />
            <br />
            <p className="font_small">faStoreAltSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStoreSlash} />
            <br />
            <p className="font_small">faStoreSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStream} />
            <br />
            <p className="font_small">faStream</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStreetView} />
            <br />
            <p className="font_small">faStreetView</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStrikethrough} />
            <br />
            <p className="font_small">faStrikethrough</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faStroopwafel} />
            <br />
            <p className="font_small">faStroopwafel</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSubscript} />
            <br />
            <p className="font_small">faSubscript</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSubway} />
            <br />
            <p className="font_small">faSubway</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSuitcase} />
            <br />
            <p className="font_small">faSuitcase</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSuitcaseRolling} />
            <br />
            <p className="font_small">faSuitcaseRolling</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSun} />
            <br />
            <p className="font_small">faSun</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSuperscript} />
            <br />
            <p className="font_small">faSuperscript</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSurprise} />
            <br />
            <p className="font_small">faSurprise</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSwatchbook} />
            <br />
            <p className="font_small">faSwatchbook</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSwimmer} />
            <br />
            <p className="font_small">faSwimmer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSwimmingPool} />
            <br />
            <p className="font_small">faSwimmingPool</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSynagogue} />
            <br />
            <p className="font_small">faSynagogue</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSync} />
            <br />
            <p className="font_small">faSync</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSyncAlt} />
            <br />
            <p className="font_small">faSyncAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faSyringe} />
            <br />
            <p className="font_small">faSyringe</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTable} />
            <br />
            <p className="font_small">faTable</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTableTennis} />
            <br />
            <p className="font_small">faTableTennis</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTablet} />
            <br />
            <p className="font_small">faTablet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTabletAlt} />
            <br />
            <p className="font_small">faTabletAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTablets} />
            <br />
            <p className="font_small">faTablets</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTachometerAlt} />
            <br />
            <p className="font_small">faTachometerAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTag} />
            <br />
            <p className="font_small">faTag</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTags} />
            <br />
            <p className="font_small">faTags</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTape} />
            <br />
            <p className="font_small">faTape</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTasks} />
            <br />
            <p className="font_small">faTasks</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTaxi} />
            <br />
            <p className="font_small">faTaxi</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTeeth} />
            <br />
            <p className="font_small">faTeeth</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTeethOpen} />
            <br />
            <p className="font_small">faTeethOpen</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTemperatureHigh} />
            <br />
            <p className="font_small">faTemperatureHigh</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTemperatureLow} />
            <br />
            <p className="font_small">faTemperatureLow</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTenge} />
            <br />
            <p className="font_small">faTenge</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTerminal} />
            <br />
            <p className="font_small">faTerminal</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTextHeight} />
            <br />
            <p className="font_small">faTextHeight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTextWidth} />
            <br />
            <p className="font_small">faTextWidth</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTh} />
            <br />
            <p className="font_small">faTh</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThLarge} />
            <br />
            <p className="font_small">faThLarge</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThList} />
            <br />
            <p className="font_small">faThList</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTheaterMasks} />
            <br />
            <p className="font_small">faTheaterMasks</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThermometer} />
            <br />
            <p className="font_small">faThermometer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThermometerEmpty} />
            <br />
            <p className="font_small">faThermometerEmpty</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThermometerFull} />
            <br />
            <p className="font_small">faThermometerFull</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThermometerHalf} />
            <br />
            <p className="font_small">faThermometerHalf</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThermometerQuarter} />
            <br />
            <p className="font_small">faThermometerQuarter</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThermometerThreeQuarters} />
            <br />
            <p className="font_small">faThermometerThreeQuarters</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThumbsDown} />
            <br />
            <p className="font_small">faThumbsDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThumbsUp} />
            <br />
            <p className="font_small">faThumbsUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faThumbtack} />
            <br />
            <p className="font_small">faThumbtack</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTicketAlt} />
            <br />
            <p className="font_small">faTicketAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTimes} />
            <br />
            <p className="font_small">faTimes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTimesCircle} />
            <br />
            <p className="font_small">faTimesCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTint} />
            <br />
            <p className="font_small">faTint</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTintSlash} />
            <br />
            <p className="font_small">faTintSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTired} />
            <br />
            <p className="font_small">faTired</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToggleOff} />
            <br />
            <p className="font_small">faToggleOff</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToggleOn} />
            <br />
            <p className="font_small">faToggleOn</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToilet} />
            <br />
            <p className="font_small">faToilet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToiletPaper} />
            <br />
            <p className="font_small">faToiletPaper</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToiletPaperSlash} />
            <br />
            <p className="font_small">faToiletPaperSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToolbox} />
            <br />
            <p className="font_small">faToolbox</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTools} />
            <br />
            <p className="font_small">faTools</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTooth} />
            <br />
            <p className="font_small">faTooth</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTorah} />
            <br />
            <p className="font_small">faTorah</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faToriiGate} />
            <br />
            <p className="font_small">faToriiGate</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTractor} />
            <br />
            <p className="font_small">faTractor</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrademark} />
            <br />
            <p className="font_small">faTrademark</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrafficLight} />
            <br />
            <p className="font_small">faTrafficLight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrailer} />
            <br />
            <p className="font_small">faTrailer</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrain} />
            <br />
            <p className="font_small">faTrain</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTram} />
            <br />
            <p className="font_small">faTram</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTransgender} />
            <br />
            <p className="font_small">faTransgender</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTransgenderAlt} />
            <br />
            <p className="font_small">faTransgenderAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrash} />
            <br />
            <p className="font_small">faTrash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrashAlt} />
            <br />
            <p className="font_small">faTrashAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrashRestore} />
            <br />
            <p className="font_small">faTrashRestore</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrashRestoreAlt} />
            <br />
            <p className="font_small">faTrashRestoreAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTree} />
            <br />
            <p className="font_small">faTree</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTrophy} />
            <br />
            <p className="font_small">faTrophy</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTruck} />
            <br />
            <p className="font_small">faTruck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTruckLoading} />
            <br />
            <p className="font_small">faTruckLoading</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTruckMonster} />
            <br />
            <p className="font_small">faTruckMonster</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTruckMoving} />
            <br />
            <p className="font_small">faTruckMoving</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTruckPickup} />
            <br />
            <p className="font_small">faTruckPickup</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTshirt} />
            <br />
            <p className="font_small">faTshirt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTty} />
            <br />
            <p className="font_small">faTty</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faTv} />
            <br />
            <p className="font_small">faTv</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUmbrella} />
            <br />
            <p className="font_small">faUmbrella</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUmbrellaBeach} />
            <br />
            <p className="font_small">faUmbrellaBeach</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUnderline} />
            <br />
            <p className="font_small">faUnderline</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUndo} />
            <br />
            <p className="font_small">faUndo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUndoAlt} />
            <br />
            <p className="font_small">faUndoAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUniversalAccess} />
            <br />
            <p className="font_small">faUniversalAccess</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUniversity} />
            <br />
            <p className="font_small">faUniversity</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUnlink} />
            <br />
            <p className="font_small">faUnlink</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUnlock} />
            <br />
            <p className="font_small">faUnlock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUnlockAlt} />
            <br />
            <p className="font_small">faUnlockAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUpload} />
            <br />
            <p className="font_small">faUpload</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUser} />
            <br />
            <p className="font_small">faUser</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserAlt} />
            <br />
            <p className="font_small">faUserAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserAltSlash} />
            <br />
            <p className="font_small">faUserAltSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserAstronaut} />
            <br />
            <p className="font_small">faUserAstronaut</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserCheck} />
            <br />
            <p className="font_small">faUserCheck</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserCircle} />
            <br />
            <p className="font_small">faUserCircle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserClock} />
            <br />
            <p className="font_small">faUserClock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserCog} />
            <br />
            <p className="font_small">faUserCog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserEdit} />
            <br />
            <p className="font_small">faUserEdit</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserFriends} />
            <br />
            <p className="font_small">faUserFriends</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserGraduate} />
            <br />
            <p className="font_small">faUserGraduate</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserInjured} />
            <br />
            <p className="font_small">faUserInjured</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserLock} />
            <br />
            <p className="font_small">faUserLock</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserMd} />
            <br />
            <p className="font_small">faUserMd</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserMinus} />
            <br />
            <p className="font_small">faUserMinus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserNinja} />
            <br />
            <p className="font_small">faUserNinja</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserNurse} />
            <br />
            <p className="font_small">faUserNurse</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserPlus} />
            <br />
            <p className="font_small">faUserPlus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserSecret} />
            <br />
            <p className="font_small">faUserSecret</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserShield} />
            <br />
            <p className="font_small">faUserShield</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserSlash} />
            <br />
            <p className="font_small">faUserSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserTag} />
            <br />
            <p className="font_small">faUserTag</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserTie} />
            <br />
            <p className="font_small">faUserTie</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUserTimes} />
            <br />
            <p className="font_small">faUserTimes</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUsers} />
            <br />
            <p className="font_small">faUsers</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUsersCog} />
            <br />
            <p className="font_small">faUsersCog</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUsersSlash} />
            <br />
            <p className="font_small">faUsersSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUtensilSpoon} />
            <br />
            <p className="font_small">faUtensilSpoon</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faUtensils} />
            <br />
            <p className="font_small">faUtensils</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVectorSquare} />
            <br />
            <p className="font_small">faVectorSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVenus} />
            <br />
            <p className="font_small">faVenus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVenusDouble} />
            <br />
            <p className="font_small">faVenusDouble</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVenusMars} />
            <br />
            <p className="font_small">faVenusMars</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVest} />
            <br />
            <p className="font_small">faVest</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVestPatches} />
            <br />
            <p className="font_small">faVestPatches</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVial} />
            <br />
            <p className="font_small">faVial</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVials} />
            <br />
            <p className="font_small">faVials</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVideo} />
            <br />
            <p className="font_small">faVideo</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVideoSlash} />
            <br />
            <p className="font_small">faVideoSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVihara} />
            <br />
            <p className="font_small">faVihara</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVirus} />
            <br />
            <p className="font_small">faVirus</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVirusSlash} />
            <br />
            <p className="font_small">faVirusSlash</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faViruses} />
            <br />
            <p className="font_small">faViruses</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVoicemail} />
            <br />
            <p className="font_small">faVoicemail</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVolleyballBall} />
            <br />
            <p className="font_small">faVolleyballBall</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVolumeDown} />
            <br />
            <p className="font_small">faVolumeDown</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVolumeMute} />
            <br />
            <p className="font_small">faVolumeMute</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVolumeOff} />
            <br />
            <p className="font_small">faVolumeOff</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVolumeUp} />
            <br />
            <p className="font_small">faVolumeUp</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVoteYea} />
            <br />
            <p className="font_small">faVoteYea</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faVrCardboard} />
            <br />
            <p className="font_small">faVrCardboard</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWalking} />
            <br />
            <p className="font_small">faWalking</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWallet} />
            <br />
            <p className="font_small">faWallet</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWarehouse} />
            <br />
            <p className="font_small">faWarehouse</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWater} />
            <br />
            <p className="font_small">faWater</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWaveSquare} />
            <br />
            <p className="font_small">faWaveSquare</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWeight} />
            <br />
            <p className="font_small">faWeight</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWeightHanging} />
            <br />
            <p className="font_small">faWeightHanging</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWheelchair} />
            <br />
            <p className="font_small">faWheelchair</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWifi} />
            <br />
            <p className="font_small">faWifi</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWind} />
            <br />
            <p className="font_small">faWind</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWindowClose} />
            <br />
            <p className="font_small">faWindowClose</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWindowMaximize} />
            <br />
            <p className="font_small">faWindowMaximize</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWindowMinimize} />
            <br />
            <p className="font_small">faWindowMinimize</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWindowRestore} />
            <br />
            <p className="font_small">faWindowRestore</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWineBottle} />
            <br />
            <p className="font_small">faWineBottle</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWineGlass} />
            <br />
            <p className="font_small">faWineGlass</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWineGlassAlt} />
            <br />
            <p className="font_small">faWineGlassAlt</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWonSign} />
            <br />
            <p className="font_small">faWonSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faWrench} />
            <br />
            <p className="font_small">faWrench</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faXRay} />
            <br />
            <p className="font_small">faXRay</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faYenSign} />
            <br />
            <p className="font_small">faYenSign</p>
        </div>
    

        <div className="icon-fa mr-2 text-center">
            <FontAwesomeIcon icon={faYinYang} />
            <br />
            <p className="font_small">faYinYang</p>
        </div>
    

    </>
  );
};
export default Help;